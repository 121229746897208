import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import {
  FaRegListAlt,
  FaEllipsisH,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import Avatar from "react-nice-avatar"; // Add this import

const UserMenu = ({
  userEmail,
  handleLogout,
  handleMyPlan,
  handleOpenSettingsModal,
  handleAbout,
  handlePrivacy,
  handleterms,
  fetchWithAuth, // Add this prop
  serverUrl, // Add this prop
  avatarConfig,
  setAvatarConfig,
}) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsNavbarOpen(false);

        setIsMoreOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsNavbarOpen(!isNavbarOpen)}
        className="inline-flex items-center space-x-2 bg-transparent text-gray-700 dark:text-gray-200 font-bold py-2 px-0 rounded focus:outline-none focus:shadow-outline"
      >
        {avatarConfig ? (
          <Avatar style={{ width: "32px", height: "32px" }} {...avatarConfig} />
        ) : (
          <div className="flex items-center justify-center h-7 w-8 rounded-full bg-purple-500 text-white text-sm font-bold uppercase">
            {userEmail ? userEmail.substring(0, 2).toUpperCase() : "NN"}
          </div>
        )}
      </button>

      {isNavbarOpen && (
        <nav className="absolute top-full right-2 flex flex-col space-y-2 w-44 bg-white dark:bg-gray-900 shadow-lg rounded-lg p-2">
          <Link
            to="/myPlan"
            onClick={(e) => {
              e.preventDefault();
              handleMyPlan();
            }}
            className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
          >
            <span className="flex items-center p-2 rounded-lg transition-all duration-200">
              <FaRegListAlt className="text-purple-600 mr-3" />
              <span>My Plans</span>
            </span>
          </Link>
          <button
            onClick={handleOpenSettingsModal}
            className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
          >
            <span className="flex items-center p-2 rounded-lg transition-all duration-200">
              <IoSettingsOutline className="text-purple-600 mr-3" />
              <span>Settings</span>
            </span>
          </button>
          <Link
            to="/api/login"
            onClick={(e) => {
              e.preventDefault();
              handleLogout();
            }}
            className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
          >
            <span className="flex items-center p-2 rounded-lg transition-all duration-200">
              <FiLogOut className="text-purple-600 mr-3" />
              <span>Logout</span>
            </span>
          </Link>
          <div className="relative">
            <button
              onClick={() => setIsMoreOpen(!isMoreOpen)}
              className="flex rounded text-left w-full px-4 py-2 text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 transition-all duration-200"
            >
              <span className="flex items-center p-2 rounded-lg transition-all duration-200">
                <FaEllipsisH className="text-purple-600 mr-2" />
                <span>More</span>
              </span>
            </button>
            {isMoreOpen && (
              <div className="absolute bottom-5 right-40 w-64 bg-white dark:bg-gray-900 shadow-lg rounded-lg p-4 z-20">
                <div className="space-y-2">
                  <a
                    href="https://x.com/ResponxeAI"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition-all duration-200"
                  >
                    <FaTwitter className="text-blue-400 mr-3" />
                    <span className="text-gray-800 dark:text-gray-200">
                      Follow us on X
                    </span>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/responxe/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition-all duration-200"
                  >
                    <FaLinkedin className="text-blue-700 mr-3" />
                    <span className="text-gray-800 dark:text-gray-200">
                      Connect on LinkedIn
                    </span>
                  </a>
                </div>
                <div className="border-t my-4 border-gray-200 dark:border-gray-700"></div>
                <div className="space-y-2">
                  <Link
                    to="/feedback"
                    className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition-all duration-200"
                  >
                    <span className="text-gray-800 dark:text-gray-200">
                      Share Feedback
                    </span>
                  </Link>
                  <Link
                    to="/about"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAbout();
                    }}
                    className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition-all duration-200"
                  >
                    <span className="text-gray-800 dark:text-gray-200">
                      About Us
                    </span>
                  </Link>
                </div>
                <div className="mt-4 flex justify-between text-sm text-gray-500 dark:text-gray-400">
                  <Link
                    to="/terms"
                    onClick={(e) => {
                      e.preventDefault();
                      handleterms();
                    }}
                    className="hover:underline"
                  >
                    Terms of Service
                  </Link>
                  <Link
                    to="/privacy"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePrivacy();
                    }}
                    className="hover:underline"
                  >
                    Privacy Policy
                  </Link>
                  {/* <Link to="/help" className="hover:underline">
                    Help
                  </Link> */}
                </div>
              </div>
            )}
          </div>
        </nav>
      )}
    </div>
  );
};

export default UserMenu;

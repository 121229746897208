import React from "react";

function Hero() {
  return (
    <section className="bg-gray-50 dark:bg-gray-800 py-20">
      <div className="container mx-auto text-center px-4">
        <h1 className="text-4xl font-bold mb-4">
          Access the World's Top AI Models Effortlessly
        </h1>
        <p className="text-xl mb-8">
          Unlock unlimited AI interactions without limitations, all for as low
          as $10.
        </p>
        <a
          href="/signup"
          className="bg-gray-800 text-white hover:bg-gray-950 dark:text-black dark:bg-gray-50 black:hover:bg-gray-200 px-6 py-3 rounded-md font-semibold"
        >
          Get Started Now
        </a>
      </div>
    </section>
  );
}
export default Hero;

import React, { useEffect, useRef } from "react";

const AutoGrowingTextArea = ({ value, onChange }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset height to auto to calculate proper scrollHeight
      textarea.style.height = "auto";

      // Get the scroll height (total content height)
      const scrollHeight = textarea.scrollHeight;

      // If content height is less than max height, set height to content height
      // Otherwise, keep it at max height and let it scroll
      const maxHeight = 300; // 300px max height
      textarea.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
    }
  }, [value]);

  return (
    <div className="relative w-full">
      <pre className="w-full border border-gray-300 dark:border-gray-600 rounded font-sans text-[16px] leading-[1.5] text-gray-800 dark:text-gray-200 whitespace-pre-wrap min-h-[60px]">
        <textarea
          ref={textareaRef}
          value={value}
          onChange={onChange}
          className="w-full bg-transparent outline-none text-gray-800 dark:text-gray-200 p-2 min-h-[60px] max-h-[300px] overflow-y-auto resize-none"
          style={{
            fontFamily: "inherit",
            fontSize: "inherit",
            lineHeight: "inherit",
          }}
        />
      </pre>
    </div>
  );
};

export default AutoGrowingTextArea;

import React from "react";

function Comparison() {
  const data = [
    {
      feature: "Pricing",
      yourPlatform: "$10/month",
      chatGPT: "$20/month",
      claude: "$20/month",
    },
    {
      feature: "Access Limits",
      yourPlatform: "Unlimited",
      chatGPT: "Limited",
      claude: "Limited",
    },
    {
      feature: "Number of Models",
      yourPlatform: "Multiple",
      chatGPT: "Single",
      claude: "Single",
    },
    {
      feature: "Support",
      yourPlatform: "24/7",
      chatGPT: "Email Only",
      claude: "Email Only",
    },
  ];
  return (
    <section id="comparison" className="py-20 bg-white dark:bg-black">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">
          We Offer More Than Just Access
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b dark:border-gray-700">
                  Features
                </th>
                <th className="py-2 px-4 border-b dark:border-gray-700">
                  Responxe
                </th>
                <th className="py-2 px-4 border-b dark:border-gray-700">
                  ChatGPT
                </th>
                <th className="py-2 px-4 border-b dark:border-gray-700">
                  Claude
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index} className="text-center">
                  <td className="py-2 px-4 border-b dark:border-gray-700">
                    {row.feature}
                  </td>
                  <td className="py-2 px-4 border-b dark:border-gray-700">
                    {row.yourPlatform}
                  </td>
                  <td className="py-2 px-4 border-b dark:border-gray-700">
                    {row.chatGPT}
                  </td>
                  <td className="py-2 px-4 border-b dark:border-gray-700">
                    {row.claude}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Comparison;

import React from "react";

function CTABanner() {
  return (
    <section className="bg-gray-50 dark:bg-gray-800 py-20">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-8 text-black dark:text-white">
          Ready to Experience Unrestricted AI Access?
        </h2>
        <a
          href="/signup"
          className="bg-gray-800 text-white hover:bg-gray-950 dark:text-black dark:bg-gray-50 black:hover:bg-gray-200 px-6 py-3 rounded-md font-semibold"
        >
          Sign Up Now
        </a>
      </div>
    </section>
  );
}

export default CTABanner;

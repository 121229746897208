import React from "react";

function Features() {
  const features = [
    {
      title: "Wide Range of AI Models",
      description:
        "Access a collection of top generative AI models in one place.",
      icon: "🌐",
    },
    {
      title: "Unlimited Interactions",
      description: "No usage caps—enjoy uninterrupted access anytime.",
      icon: "♾️",
    },
    {
      title: "Affordable Pricing",
      description: "Premium AI access starting at just $10.",
      icon: "💰",
    },
    {
      title: "User-Friendly Interface",
      description: "Easy-to-use platform designed for everyone.",
      icon: "👌",
    },
  ];

  return (
    <section id="features" className="bg-white dark:bg-black py-20">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-12">Why Choose Our Platform?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 auto-rows-fr">
          {features.map((feature, index) => (
            <div key={index}>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow hover:shadow-lg transition flex flex-col h-full">
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600 dark:text-gray-400 flex-grow">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Features;

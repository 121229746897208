import React from "react";
import { Link } from "react-router-dom";
import { FcOk } from "react-icons/fc";
// Auto-picks top model based on query type
const Navigation = ({ handleModelChange, userPlanType, selectedModel }) => {
  const models = [
    {
      name: "Auto Model Selector",
      description: "Chooses best-fit model automatically per query",
    },
    {
      name: "GPT 4o mini",
      description: "Lightweight version, great for simple tasks",
    },
    { name: "GPT 4o", description: "Great for most questions" },
    { name: "Claude 3.5 Haiku", description: "Perfect for generating haikus" },
    {
      name: "Claude 3.5 Sonnet",
      description: "Excellent for writing sonnets",
    },
    { name: "Grok 2", description: "Advanced reasoning capabilities" },
    {
      name: "Gemini 1.5 Pro",
      description: "Professional and versatile model",
    },
    { name: "Gemini 2 Flash", description: "Fast responsiveness for tasks" },
    {
      name: "Gemini 2 Flash Thinking",
      description: "Advanced features for complex queries",
    },
    // { name: "Machine Learning", color: "black" },
    // { name: "Programming", color: "black" },
  ];

  // Define which models are accessible for free users
  const accessibleModels = [
    "GPT 4o mini",
    "Claude 3.5 Haiku",
    "Gemini 2 Flash",
  ];
  // const isFreeUser = userPlanType === "free";
  const isFreeUser = userPlanType?.toLowerCase() === "free";

  return (
    <nav className="navbar flex flex-col space-y-2 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      {models.map((model) => {
        const isAccessible = accessibleModels.includes(model.name);
        const isRestricted = isFreeUser && !isAccessible;

        return (
          <div
            key={model.name}
            className={`relative group cursor-pointer rounded ${
              isRestricted ? "cursor-not-allowed" : ""
            }`}
            onClick={() => {
              if (!isFreeUser || isAccessible) {
                handleModelChange(model.name);
              }
            }}
          >
            <div
              className={`p-2 transition-colors duration-200 group-hover:bg-gray-200 rounded ${
                isRestricted ? "" : "hover:bg-gray-200"
              }`}
            >
              <div
                className={`text-left w-full ${
                  isRestricted
                    ? "text-gray-400"
                    : "text-gray-800 dark:text-gray-200"
                }`}
                title={
                  isRestricted ? "Upgrade your plan to access this model" : ""
                }
              >
                <div className="flex items-center justify-between">
                  <span className="font-sans">{model.name}</span>
                  <div className="flex items-center">
                    {isRestricted && <span className="ml-1">🔒</span>}
                    {selectedModel === model.name && <FcOk className="ml-2" />}
                  </div>
                </div>
              </div>
              <p
                className={`text-gray-400 dark:text-gray-600 font-sans ${
                  isRestricted ? "cursor-not-allowed" : ""
                }`}
                style={{ fontSize: "11px" }}
              >
                {model.description}
              </p>
            </div>
          </div>
        );
      })}
    </nav>
  );
};

export default Navigation;

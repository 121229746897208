import React, { useMemo, useState, useEffect } from "react";
import { BsTrash3 } from "react-icons/bs";
import axios from "axios";
import { FaCaretDown } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";

const ChatCategories = ({
  savedChats,
  chatNames,
  loadChat,
  fetchSavedChats,
  serverUrl,
  fetchWithAuth,
}) => {
  const [categoryOpenState, setCategoryOpenState] = useState({
    today: true,
    yesterday: false,
    lastWeek: false,
    lastMonth: false,
    last90Days: false,
  });

  const [dropdownOpen, setDropdownOpen] = useState({});
  const [editingChat, setEditingChat] = useState(null);
  const [newChatName, setNewChatName] = useState("");

  const toggleDropdown = (chatId, e) => {
    e.stopPropagation();
    setDropdownOpen((prev) => ({
      ...prev,
      [chatId]: !prev[chatId],
    }));
  };

  const handleRename = (chatId, currentName) => {
    setEditingChat(chatId);
    setNewChatName(currentName);
    setDropdownOpen((prev) => ({ ...prev, [chatId]: false }));
  };

  const handleClickOutside = (event, chatId) => {
    if (
      dropdownOpen[chatId] &&
      event.target.closest(".dropdown-container") === null
    ) {
      setDropdownOpen((prev) => ({ ...prev, [chatId]: false }));
    }
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      Object.keys(dropdownOpen).forEach((chatId) => {
        handleClickOutside(event, chatId);
      });
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [dropdownOpen]);

  const submitRename = async (chatId) => {
    if (!newChatName.trim()) {
      setEditingChat(null);
      return;
    }

    try {
      const response = await fetchWithAuth(
        `${serverUrl}/api/rename-chat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chatId,
            newName: newChatName.trim(),
          }),
        },
        {
          withCredentials: true,
          timeout: 10000,
        }
      );

      if (response.status === 200) {
        // Update the local chat names immediately
        chatNames[chatId] = newChatName.trim();
        await fetchSavedChats(); // Refresh the full list
        setEditingChat(null);
        setNewChatName("");
        setDropdownOpen({}); // Close any open dropdowns
      }
    } catch (error) {
      console.error("Error renaming chat:", error);
      alert("Failed to rename chat. Please try again.");
    }
  };

  // const handleKeyPress = (e, chatId) => {
  //   if (e.key === "Enter") {
  //     submitRename(chatId);
  //   } else if (e.key === "Escape") {
  //     setEditingChat(null);
  //     setNewChatName("");
  //   }
  // };
  const handleKeyPress = async (e, chatId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await submitRename(chatId);
    } else if (e.key === "Escape") {
      setEditingChat(null);
      setNewChatName("");
      setDropdownOpen({});
    }
  };

  const categorizeChats = useMemo(() => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeekStart = new Date(today);
    lastWeekStart.setDate(lastWeekStart.getDate() - 7);
    const lastMonthStart = new Date(today);
    lastMonthStart.setDate(lastMonthStart.getDate() - 30);
    const last90DaysStart = new Date(today);
    last90DaysStart.setDate(last90DaysStart.getDate() - 90);

    return savedChats.reduce(
      (acc, chat) => {
        const chatDate = new Date(chat.createdAt);
        if (chatDate >= today) {
          acc.today.push(chat);
        } else if (chatDate >= yesterday) {
          acc.yesterday.push(chat);
        } else if (chatDate >= lastWeekStart) {
          acc.lastWeek.push(chat);
        } else if (chatDate >= lastMonthStart) {
          acc.lastMonth.push(chat);
        } else if (chatDate >= last90DaysStart) {
          acc.last90Days.push(chat);
        } else {
          acc.older.push(chat);
        }
        return acc;
      },
      {
        today: [],
        yesterday: [],
        lastWeek: [],
        lastMonth: [],
        last90Days: [],
        older: [],
      }
    );
  }, [savedChats]);

  const toggleCategory = (category) => {
    setCategoryOpenState((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // const truncateChatName = (name, maxLength = 25) => {
  //   if (name.length <= maxLength) return name;
  //   return `${name.substring(0, maxLength)}...`;
  // };
  const truncateChatName = (name, maxLength = 26) => {
    if (name.length <= maxLength) return name;
    return name.substring(0, maxLength);
  };

  const handleDeleteChat = async (chatId) => {
    console.log("I am in handle dlete and here is my chat Id: ", chatId);
    try {
      const response = await axios.delete(
        `${serverUrl}/api/delete-chat/${chatId}`,
        // `/api/delete-chat/${chatId}`,
        {
          withCredentials: true,
          timeout: 10000,
        }
      );
      if (response.status === 200) {
        fetchSavedChats(); // Refresh chat list after deletion
      } else {
        console.error("Failed to delete chat");
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  const renderChatList = (chats, category) => {
    if (chats.length === 0) {
      return (
        <p className="text-gray-500 dark:text-gray-400 italic">
          No chats available
        </p>
      );
    }
    return (
      <ul
        className={`space-y-2 ${
          categoryOpenState[category] ? "block" : "hidden"
        }`}
      >
        {chats.map((chat) => (
          <li
            key={chat.chatId}
            className="group cursor-pointer px-1 py-2 rounded text-left w-full text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-150 flex justify-between items-center"
          >
            <div className="relative overflow-hidden flex-grow mr-2">
              {editingChat === chat.chatId ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitRename(chat.chatId);
                  }}
                >
                  <input
                    type="text"
                    value={newChatName}
                    onChange={(e) => setNewChatName(e.target.value)}
                    onKeyDown={(e) => handleKeyPress(e, chat.chatId)}
                    onBlur={() => submitRename(chat.chatId)}
                    className="w-full px-2 py-1 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded"
                    autoFocus
                  />
                </form>
              ) : (
                <span
                  style={{
                    fontFamily: "'Arial', sans-serif",
                    fontSize: "0.855rem",
                  }}
                  onClick={() => loadChat(chat.chatId)}
                  title={
                    chatNames[chat.chatId] || chat.chatName || `Chat ${chat.id}`
                  }
                  className="block w-full h-full"
                >
                  {truncateChatName(
                    chatNames[chat.chatId] || chat.chatName || `Chat ${chat.id}`
                  )}
                </span>
              )}
            </div>
            <div className="relative flex items-center dropdown-container">
              <button
                onClick={(e) => toggleDropdown(chat.chatId, e)}
                className="p-1 hover:bg-gray-300 dark:hover:bg-gray-600 rounded"
              >
                <FaCaretDown className="text-gray-500 dark:text-gray-400" />
              </button>

              {dropdownOpen[chat.chatId] && (
                <div className="absolute right-0 top-full mt-1 w-32 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded shadow-lg z-10">
                  <button
                    onClick={() =>
                      handleRename(
                        chat.chatId,
                        chatNames[chat.chatId] ||
                          chat.chatName ||
                          `Chat ${chat.id}`
                      )
                    }
                    className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center gap-2"
                  >
                    <FiEdit3 className="text-gray-500" />
                    Rename
                  </button>
                  <button
                    onClick={() => handleDeleteChat(chat.chatId)}
                    className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center gap-2 text-red-500"
                  >
                    <BsTrash3 />
                    Delete
                  </button>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const renderCategory = (category, label) => {
    const chats = categorizeChats[category];
    if (chats.length === 0) return null;
    return (
      <div>
        <button
          onClick={() => toggleCategory(category)}
          className="flex items-center justify-between w-full p-2 hover:bg-gray-200 dark:hover:bg-gray-700 rounded text-gray-600 dark:text-gray-300 font-semibold text-sm font-sans"
        >
          <span>{label}</span>
        </button>
        {renderChatList(chats, category)}
      </div>
    );
  };

  return (
    <div>
      {renderCategory("today", "Today")}
      {renderCategory("yesterday", "Yesterday")}
      {renderCategory("lastWeek", "Previous 7 days")}
      {renderCategory("lastMonth", "Previous 30 days")}
      {renderCategory("last90Days", "Previous 90 days")}
      {renderCategory("older", "Older than 90 days")}
    </div>
  );
};

export default ChatCategories;

// MyPlan.jsx
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { BsHouse } from "react-icons/bs";
import { useTheme } from "./ThemeContext";
import { useNavigate } from "react-router-dom";
import useFetchWithAuth from "./MainPageComponents/useFetchWithAuth.js";
import CheckoutSummary from "./MyPlan/CheckoutSummary.jsx";

function MyPlan() {
  const [activeTab, setActiveTab] = useState("Pro");
  const [notification, setNotification] = useState("");
  const [showCheckoutSummary, setShowCheckoutSummary] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState("");
  const [discount, setDiscount] = useState(0);
  const location = useLocation();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const userEmail = location.state?.userEmail;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const fetchWithAuth = useFetchWithAuth(serverUrl);

  const STRIPE_PRICE_IDS = {
    daily: "price_1Qjo3ARwIvlITD2XYC6J94Av",

    pro_monthly: "price_1QiW5URwIvlITD2XDqr4TkID",
    pro_quarterly: "price_1QiUzRRwIvlITD2XIiDmPn0I",
    pro_yearly: "price_1QiV6MRwIvlITD2XKVqDkBRW",

    premium_monthly: "price_1QiV79RwIvlITD2X3vl68dLH",
    premium_quarterly: "price_1QiV7aRwIvlITD2XOti8U8cH",
    premium_yearly: "price_1QiVBNRwIvlITD2X2sD06tE3",
  };
  const STRIPE_fifty_PRICE_IDS = {
    pro_monthly_fifty: "price_1QikBERwIvlITD2XFG1moQgt",
    pro_quarterly_fifty: "price_1QiUzRRwIvlITD2XIiDmPn0I",
    pro_yearly_fifty: "price_1QiV6MRwIvlITD2XKVqDkBRW",

    premium_monthly_fifty: "price_1QiV79RwIvlITD2X3vl68dLH",
    premium_quarterly_fifty: "price_1QiV7aRwIvlITD2XOti8U8cH",
    premium_yearly_fifty: "price_1QiVBNRwIvlITD2X2sD06tE3",
  };

  // const calculateStripeFee = (amount) => {
  //   return (amount * 0.029 + 0.3).toFixed(2);
  // };

  const calculateTotal = (subtotal, discount) => {
    const discountedAmount = subtotal - discount;
    // const stripeFee = parseFloat(calculateStripeFee(discountedAmount));
    const tax = parseFloat((discountedAmount * 0.08).toFixed(2));
    // return (discountedAmount + stripeFee + tax).toFixed(2);
    return (discountedAmount + tax).toFixed(2);
  };

  const validateCoupon = async () => {
    console.log("Starting coupon validation...");
    setCouponError("");

    if (!selectedPlan) {
      console.log("No plan selected");
      setCouponError("Please select a plan first");
      return false;
    }

    if (!couponCode) {
      console.log("No coupon code entered");
      setCouponError("Please enter a coupon code");
      return false;
    }

    console.log("Validating coupon with:", {
      couponCode,
      planType: selectedPlan.planName,
      selectedPlan,
    });

    try {
      const response = await fetchWithAuth(`${serverUrl}/api/validate-coupon`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          couponCode: couponCode.trim().toUpperCase(),
          planType: selectedPlan.planName,
        }),
      });

      console.log("Coupon validation response status:", response.status);
      const data = await response.json();
      console.log("Coupon validation response data:", data);

      if (!response.ok) {
        setCouponError(data.message || "Failed to validate coupon");
        setDiscount(0);
        return false;
      }

      if (data.valid) {
        console.log("Coupon valid, applying discount:", data.discountAmount);
        setDiscount(data.discountAmount);
        // Recalculate total here if needed
        return true;
      } else {
        console.log("Coupon invalid:", data.message);
        setCouponError(data.message || "The entered coupon is not valid");
        setDiscount(0);
        return false;
      }
    } catch (error) {
      console.error("Error validating coupon:", error);
      setCouponError("Error validating coupon. Please try again.");
      setDiscount(0);
      return false;
    }
  };

  const makePayment = async (plan) => {
    if (plan.planName === "recharge") {
      setNotification("You need to first subscribe to a plan.");
      return;
    }

    // Clear any existing notifications
    setNotification("");
    setSelectedPlan(plan);
    setShowCheckoutSummary(true);
  };

  // Helper: map your planName to the corresponding “fifty” price ID
  const getFiftyPriceId = (planName) => {
    switch (planName) {
      // “basic” lines map to “pro_..._fifty”
      case "pro_monthly":
        return STRIPE_fifty_PRICE_IDS.pro_monthly_fifty;
      case "pro_quarterly":
        return STRIPE_fifty_PRICE_IDS.pro_quarterly_fifty;
      case "pro_yearly":
        return STRIPE_fifty_PRICE_IDS.pro_yearly_fifty;

      // “pro” lines map to “premium_..._fifty”
      case "premium_monthly":
        return STRIPE_fifty_PRICE_IDS.premium_monthly_fifty;
      case "premium_quarterly":
        return STRIPE_fifty_PRICE_IDS.premium_quarterly_fifty;
      case "premium_yearly":
        return STRIPE_fifty_PRICE_IDS.premium_yearly_fifty;
      default:
        // fallback to original if mismatch
        return null;
    }
  };

  // Frontend (MyPlan.jsx)
  const handleProceedToStripe = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        setNotification("Please log in to continue");
        navigate("/login");
        return;
      }

      if (couponCode) {
        const isValidCoupon = await validateCoupon();
        if (!isValidCoupon) {
          return;
        }
      }

      // If coupon is "WELCOME50" and we have a matching Price ID, override the plan’s priceId
      if (couponCode.trim().toUpperCase() === "WELCOME50") {
        const fiftyId = getFiftyPriceId(selectedPlan.planName);
        if (fiftyId) {
          selectedPlan.priceId = fiftyId;
        }
      }

      const subtotal = selectedPlan.price;
      const total = calculateTotal(subtotal, discount);

      const body = {
        email: userEmail,
        planName: selectedPlan.planName,
        priceId: selectedPlan.priceId,
        couponCode: couponCode || null,

        // If you want to keep metadata in your DB, you can still pass these:
        displayedPrice: subtotal,
        displayedDiscount: discount,
        displayedFinalAmount: total,
      };
      console.log("Request Body to /create-checkout-session:", body);

      // Use fetchWithAuth instead of fetch
      const response = await fetchWithAuth(
        `${serverUrl}/api/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      // Handle non-JSON responses
      const responseText = await response.text();

      if (!response.ok) {
        if (response.status === 403) {
          setNotification("Session expired. Please log in again.");
          navigate("/login");
          return;
        }
        throw new Error(responseText || "Failed to create checkout session");
      }

      let responseData;
      try {
        responseData = JSON.parse(responseText);
      } catch (e) {
        throw new Error("Invalid response from server");
      }

      if (!responseData.id) {
        throw new Error("No session ID returned from server");
      }

      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
      if (!stripe) {
        throw new Error("Failed to initialize Stripe");
      }

      const result = await stripe.redirectToCheckout({
        sessionId: responseData.id,
      });

      if (result.error) {
        throw new Error(`Stripe redirect error: ${result.error.message}`);
      }
    } catch (error) {
      console.error("Checkout error:", error);
      setNotification(`Checkout failed: ${error.message}`);
    }
  };

  const plans = {
    Recharge: [
      {
        name: "Recharge",
        planName: "recharge",
        price: 4.99,
        description: [],
      },
    ],

    Pro: [
      {
        name: "Daily",
        planName: "daily",
        price: 2.0,
        priceId: STRIPE_PRICE_IDS.daily, // Add this line
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
        ],
      },
      {
        name: "Monthly",
        planName: "pro_monthly",
        price: 9.99,
        priceId: STRIPE_PRICE_IDS.pro_monthly, // Add this line
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
        ],
      },
      {
        name: "Quarterly",
        planName: "pro_quarterly",
        price: 26.99,
        usage: "2000 tokens",
        priceId: STRIPE_PRICE_IDS.pro_quarterly, // Add this line
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
      {
        name: "Yearly",
        planName: "pro_yearly",
        price: 99.99,
        usage: "3000 tokens",
        priceId: STRIPE_PRICE_IDS.pro_yearly,
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
    ],
    Premium: [
      {
        name: "Monthly",
        planName: "premium_monthly",
        price: 99.99,
        usage: "12000 tokens",
        priceId: STRIPE_PRICE_IDS.premium_monthly,
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      }, // 2 months free
      {
        name: "Quarterly",
        planName: "premium_quarterly",
        price: 279.99,
        priceId: STRIPE_PRICE_IDS.premium_quarterly,
        usage: "24000 tokens",

        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
      {
        name: "Yearly",
        planName: "premium_yearly",
        price: 1099.99,
        usage: "36000 tokens",
        priceId: STRIPE_PRICE_IDS.premium_yearly,
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
    ],
  };

  return (
    <div
      className={`flex flex-col h-screen overflow-hidden bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
    >
      <button
        onClick={() => navigate("/mainPage")}
        className="h-14 bg-blue-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 dark:border-gray-700 flex items-center px-4 z-20"
      >
        <BsHouse size={20} />
      </button>

      <div className="fixed inset-0 bg-blue-100 bg-opacity-75 flex items-center justify-center p-4 dark:bg-gray-900 dark:bg-opacity-80">
        <div className="dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden max-w-4xl w-full h-[500px] flex flex-col relative">
          <div className="flex justify-between px-5 pt-5 border-b dark:border-gray-700">
            <button
              className={`text-lg font-medium ml-40 ${
                activeTab === "Pro"
                  ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
                  : "text-gray-500 dark:text-gray-400"
              }`}
              onClick={() => setActiveTab("Pro")}
            >
              ResponXe Pro
            </button>
            <button
              className={`text-lg font-medium mr-40 ${
                activeTab === "Premium"
                  ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
                  : "text-gray-500 dark:text-gray-400"
              }`}
              onClick={() => setActiveTab("Premium")}
            >
              ResponXe Premium
            </button>
          </div>

          {/* Notification Section */}
          {notification && (
            <div className="px-5 pt-4">
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">Notice: </strong>
                <span className="block sm:inline">{notification}</span>
                <span
                  className="absolute top-0 bottom-0 right-0 px-4 py-3"
                  onClick={() => setNotification("")}
                >
                  <svg
                    className="fill-current h-6 w-6 text-red-500"
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <title>Close</title>
                    <path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36 5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0 00.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707 10l3.64-3.64a.5.5 0 000-.708z" />
                  </svg>
                </span>
              </div>
            </div>
          )}

          <div className="flex items-center justify-center flex-1 p-5">
            {activeTab === "Pro" && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {plans.Pro.map((plan) => (
                  <div
                    key={plan.name}
                    className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                  >
                    <div className="flex-grow">
                      <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                        {plan.name}
                      </h3>
                      <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                        ${plan.price}
                        <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                          {"/mo"}
                        </span>
                      </p>
                      <div className="mt-2 text-left pt-5">
                        {Array.isArray(plan.description) ? (
                          plan.description.map((paragraph, index) => (
                            <p
                              key={index}
                              className="font-small text-gray-700 dark:text-gray-300 mb-2"
                            >
                              {paragraph}
                            </p>
                          ))
                        ) : (
                          <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                            {plan.description}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-auto pt-5">
                      <button
                        className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                        onClick={() => makePayment(plan)}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {activeTab === "Premium" && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {plans.Premium.map((plan) => (
                  <div
                    key={plan.name}
                    className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                  >
                    <div className="flex-grow">
                      <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                        {plan.name}
                      </h3>
                      <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                        ${plan.price}
                        <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                          {"/mo"}
                        </span>
                      </p>
                      <div className="mt-2 text-left pt-5">
                        {Array.isArray(plan.description) ? (
                          plan.description.map((paragraph, index) => (
                            <p
                              key={index}
                              className="font-small text-gray-700 dark:text-gray-300 mb-2"
                            >
                              {paragraph}
                            </p>
                          ))
                        ) : (
                          <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                            {plan.description}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-auto pt-5">
                      <button
                        className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                        onClick={() => makePayment(plan)}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {activeTab === "Recharge" && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {plans.Recharge.map((plan) => (
                  <div
                    key={plan.name}
                    className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                  >
                    <div className="flex-grow">
                      <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                        {plan.name}
                      </h3>
                      <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                        ${plan.price}
                        <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                          {"/one-time"}
                        </span>
                      </p>
                      <div className="mt-2 text-left pt-5">
                        {Array.isArray(plan.description) ? (
                          plan.description.map((paragraph, index) => (
                            <p
                              key={index}
                              className="font-small text-gray-700 dark:text-gray-300 mb-2"
                            >
                              {paragraph}
                            </p>
                          ))
                        ) : (
                          <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                            {plan.description}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-auto pt-5">
                      <button
                        className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                        onClick={() => makePayment(plan)}
                      >
                        Recharge
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {showCheckoutSummary && (
        <CheckoutSummary
          selectedPlan={selectedPlan}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          validateCoupon={validateCoupon}
          couponError={couponError}
          discount={discount}
          setShowCheckoutSummary={setShowCheckoutSummary}
          handleProceedToStripe={handleProceedToStripe}
        />
      )}
    </div>
  );
}

export default MyPlan;

import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { SubmitIcon } from "./Icons";
import { FaPaperclip } from "react-icons/fa6";
import { IoCloseCircleSharp } from "react-icons/io5";
import { TbWorldSearch } from "react-icons/tb";
import FilePreview from "./FilePreview";
import { FaStopCircle } from "react-icons/fa";
import { IoIosImages } from "react-icons/io";

const SubmitHandler = forwardRef(
  (
    {
      question,
      setQuestion,
      gptVersion,
      chatId,
      setChatId,
      setChatName,
      selectedFiles,
      setSelectedFiles,
      setChatHistory,
      serverUrl,
      fetchWithAuth,
      setIsLoading,
      setError,
      setIsUsageLimitExceeded,
      isLoading,
      FileList,
      setIsForbidden,
      navigate,
      onQuestionDisplayed,
      themeClasses,
      fetchUsageData,
    },
    ref
  ) => {
    const [isInputActive, setIsInputActive] = useState(false);
    const [isWebSearch, setIsWebSearch] = useState(false);
    const [isIMGGeneration, setIsIMGGeneration] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isDraggingPreview, setIsDraggingPreview] = useState(false);
    const [abortController, setAbortController] = useState(null);

    const fileInputRef = useRef(null);
    const textareaRef = useRef(null);
    const dropZoneRef = useRef(null);
    const previewDropZoneRef = useRef(null);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        const newHeight = Math.min(textareaRef.current.scrollHeight, 200);
        textareaRef.current.style.height = `${newHeight}px`;
      }
    }, [question]);

    useImperativeHandle(ref, () => ({
      handleSubmit,
      handleStop,
    }));

    const handleStop = () => {
      if (abortController) {
        abortController.abort();
        setAbortController(null);
        setIsLoading(false);

        // Update the chat history to preserve formatting
        setChatHistory((prev) => {
          const newHistory = [...prev];
          const lastIndex = newHistory.length - 1;
          if (lastIndex >= 0) {
            newHistory[lastIndex] = {
              ...newHistory[lastIndex],
              status: "aborted",
              // Keep the existing answer format
              answer: newHistory[lastIndex].answer || "Request was cancelled",
            };
          }
          return newHistory;
        });
      }
    };

    const handleQuestionChange = (e) => {
      setQuestion(e.target.value);
      setIsInputActive(e.target.value.length > 0);
    };

    const scrollToBottom = () => {
      if (textareaRef.current) {
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault(); // Prevent default to avoid newline in textarea

        if (isLoading) {
          // If currently loading, treat Enter as stop
          handleStop();
        } else if (question.trim() || selectedFiles.length > 0) {
          // Only submit if there's text or files
          handleSubmit(e);
        }
      } else if (e.key === "Enter" && e.shiftKey) {
        // Keep the existing shift+enter behavior for newlines
        setTimeout(scrollToBottom, 0);
      }
    };

    const handleFileSelectButton = () => {
      fileInputRef.current && fileInputRef.current.click();
    };

    const handleFileSelect = (e) => {
      const files = Array.from(e.target.files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleFiles = (files) => {
      setSelectedFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        Array.from(files).forEach((file) => {
          const fileExists = newFiles.some(
            (f) =>
              f.name === file.name &&
              f.size === file.size &&
              f.type === file.type
          );
          if (!fileExists) {
            newFiles.push(file);
          }
        });
        return newFiles;
      });
    };

    // Preview area drag handlers
    const handlePreviewDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDraggingPreview(true);
    };

    const handlePreviewDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target === previewDropZoneRef.current) {
        setIsDraggingPreview(false);
      }
    };

    const handlePreviewDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = "copy";
      }
    };

    const handlePreviewDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDraggingPreview(false);
      handleFiles(e.dataTransfer.files);
    };

    // Enhanced drag and drop handlers
    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.currentTarget.contains(e.relatedTarget)) {
        return;
      }
      setIsDragging(false);
    };

    // 1) Handle drag-and-drop
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = "copy";
      }
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      handleFiles(e.dataTransfer.files);
    };

    useEffect(() => {
      const handleDragEnd = () => {
        setIsDragging(false);
      };

      window.addEventListener("dragend", handleDragEnd);
      return () => {
        window.removeEventListener("dragend", handleDragEnd);
      };
    }, []);

    const removeFile = (index) => {
      setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleWebSearch = () => {
      setIsWebSearch(!isWebSearch);
    };
    const handleIMGGeneration = () => {
      setIsIMGGeneration(!isIMGGeneration);
    };

    console.log("isIMGGeneration: ", isIMGGeneration);

    const initializeChat = async (message) => {
      try {
        const response = await fetchWithAuth(`${serverUrl}/api/create-chat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: {
              content: message.content,
              answer: message.answer,
              files: message.files,
              apiModel: message.apiModel,
              modelName: message.modelName,
            },
            chatId: "",
          }),
        });
        const data = await response.json();
        return { chatId: data.chatId, chatName: data.chatName };
      } catch (error) {
        console.error("Error initializing chat:", error);
        throw error;
      }
    };

    const updateChat = async (messageWithChatId) => {
      try {
        console.log("step 2 of updateChat");
        await fetchWithAuth(`${serverUrl}/api/update-chat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chatId: messageWithChatId.chatId,
            message: {
              content: messageWithChatId.content,
              answer: messageWithChatId.answer,
              files: messageWithChatId.files,
              apiModel: messageWithChatId.apiModel,
              modelName: messageWithChatId.modelName,
            },
          }),
        });
        console.log("step 3 of updateChat");
      } catch (error) {
        console.error("Error updating chat:", error);
        throw error;
      }
    };

    const handleSubmit = async (
      e,
      editedQuestion = null,
      editedIndex = null
    ) => {
      e.preventDefault();
      const submittedQuestion = editedQuestion || question;
      const submittedFiles = [...selectedFiles];
      setQuestion("");
      setSelectedFiles([]);
      setIsInputActive(false);

      setIsLoading(true);
      setError(null);

      const submittedIndex = editedIndex !== null ? editedIndex : -1;
      let fullAnswer = ""; // Move this declaration to the top

      // Update chat history IMMEDIATELY after submission
      setChatHistory((prev) => {
        const newHistory = [...prev];
        const newEntry = {
          question: submittedQuestion,
          answer: "",
          files: submittedFiles.map((file) => ({
            name: file.name,
            type: file.type,
          })),
          apiModel: gptVersion,
          modelName: gptVersion,
          status: "pending", // Add status to track loading state
        };

        if (submittedIndex === -1) {
          return [...newHistory, newEntry];
        } else {
          newHistory[submittedIndex] = newEntry;
          return newHistory.slice(0, submittedIndex + 1);
        }
      });

      const controller = new AbortController();
      setAbortController(controller);

      let apiModel = gptVersion;
      let modelName = gptVersion;

      try {
        const token = localStorage.getItem("accessToken");
        if (!token) {
          throw new Error("No access token found. Please log in.");
        }
        const formData = new FormData();
        formData.append("question", submittedQuestion);
        formData.append("modelVersion", gptVersion);
        formData.append("chatId", chatId || "");
        formData.append("isWebSearch", isWebSearch);
        formData.append("isIMGGeneration", isIMGGeneration);

        // selectedFiles.forEach((file) => formData.append("files", file));
        submittedFiles.forEach((file) => formData.append("files", file));

        const response = await fetchWithAuth(`${serverUrl}/api/chat`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
          credentials: "include",
          signal: controller.signal,
        });

        if (!response.ok) {
          if (response.status === 403) {
            const errorData = await response.json();
            if (errorData.error === "Usage limit exceeded") {
              setIsUsageLimitExceeded(true);
              throw new Error("Usage limit exceeded");
            }
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        onQuestionDisplayed();

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split("\n");

          for (const line of lines) {
            if (line.startsWith("data: ")) {
              const data = line.slice(5).trim();

              if (data === "[DONE]") {
                setIsLoading(false);
              } else {
                try {
                  const parsedData = data.startsWith("{")
                    ? JSON.parse(data)
                    : { content: data };
                  if (parsedData.chatId) {
                    setChatId(parsedData.chatId);
                  }
                  if (parsedData.chatName) {
                    setChatName(parsedData.chatId, parsedData.chatName);
                  }
                  if (parsedData.content) {
                    fullAnswer += parsedData.content;
                    setChatHistory((prev) => {
                      const newHistory = [...prev];
                      const targetIndex =
                        submittedIndex === -1
                          ? newHistory.length - 1
                          : submittedIndex;
                      newHistory[targetIndex] = {
                        ...newHistory[targetIndex],
                        answer: fullAnswer,
                        status: "streaming",
                      };
                      return newHistory;
                    });
                  }
                  if (parsedData.apiModel) {
                    apiModel = parsedData.apiModel;
                    modelName = parsedData.modelName; // Update modelName as well
                    setChatHistory((prev) => {
                      const newHistory = [...prev];
                      const targetIndex =
                        submittedIndex === -1
                          ? newHistory.length - 1
                          : submittedIndex;
                      newHistory[targetIndex] = {
                        ...newHistory[targetIndex],
                        apiModel,
                        modelName,
                      };
                      return newHistory;
                    });
                  }
                  if (parsedData.error) {
                    setError(parsedData.error);
                  }
                  // return fullAnswer;
                } catch (error) {
                  console.error("Error parsing chunk:", data, error);
                }
              }
            }
          }
        }
        console.log("Full answer accumulated:", fullAnswer);
        const message = {
          content: submittedQuestion,
          answer: fullAnswer,
          files: selectedFiles.map((f) => ({ name: f.name, type: f.type })),
          apiModel: apiModel,
          modelName: modelName,
        };

        const messageWithChatId = { ...message, chatId };
        console.log("messageWithChatId: ", messageWithChatId);

        if (!chatId) {
          const { chatId: newChatId, chatName } = await initializeChat(message);
          setChatId(newChatId);
          setChatName(newChatId, chatName);
        } else {
          await updateChat(messageWithChatId);
        }
        await fetchUsageData();
        return fullAnswer;
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request was aborted by user");
          setChatHistory((prev) => {
            const newHistory = [...prev];
            const targetIndex =
              submittedIndex === -1 ? newHistory.length - 1 : submittedIndex;
            newHistory[targetIndex] = {
              ...newHistory[targetIndex],
              status: "aborted",
              // answer: fullAnswer || "Request was cancelled",
              answer: newHistory[targetIndex].answer || "Request was cancelled",
            };
            return newHistory;
          });
          const message = {
            content: submittedQuestion,
            answer: fullAnswer, // This will contain the partial answer
            files: selectedFiles.map((f) => ({ name: f.name, type: f.type })),
            apiModel: gptVersion,
            modelName: gptVersion,
          };

          const messageWithChatId = { ...message, chatId };
          if (!chatId) {
            const { chatId: newChatId, chatName } = await initializeChat(
              message
            );
            setChatId(newChatId);
            setChatName(newChatId, chatName);
          } else {
            await updateChat(messageWithChatId);
          }
        } else if (error.response && error.response.status === 401) {
          navigate("/login");
        } else if (error.response && error.response.status === 403) {
          setIsForbidden(true);
        } else {
          setError(error.message);
        }
      } finally {
        setAbortController(null);
        setIsLoading(false);
      }
    };

    return (
      // <div className="w-full flex flex-col items-center relative">
      <div className="w-full max-w-[880px] mx-auto flex flex-col items-center relative">
        {isDragging && selectedFiles.length === 0 && (
          <div
            className="absolute w-full flex justify-center"
            style={{ bottom: "0" }}
          >
            <div
              className={`p-4 mb-20 rounded-2xl w-[880px] bg-blue-50 dark:bg-blue-900/20 border-2 border-dashed border-blue-400 flex flex-col items-center justify-center`}
            >
              <div className="flex items-center gap-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4V16M12 16L8 12M12 16L16 12"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20 21H4"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
                <span className="text-blue-600 dark:text-blue-400 text-lg">
                  Drop files here to add to chat
                </span>
              </div>
              <span className="text-sm text-blue-500 dark:text-blue-300 mt-2">
                Max 20 files per chat at 30 MB each
              </span>
            </div>
          </div>
        )}

        {/* Preview Area */}
        {selectedFiles.length > 0 && (
          <div
            ref={previewDropZoneRef}
            onDragEnter={handlePreviewDragEnter}
            onDragOver={handlePreviewDragOver}
            onDragLeave={handlePreviewDragLeave}
            onDrop={handlePreviewDrop}
            className={`p-4 rounded-t-2xl w-full max-w-[880px] mx-auto justify-center 
      bg-gray-200 dark:bg-gray-700 ${
        isDraggingPreview ? "border-2 border-gray-600" : ""
      } mb-[-10px]`}

            // className={`p-4 rounded-t-2xl w-[880px] justify-center bg-gray-200 dark:bg-gray-700 ${
            //   isDraggingPreview ? "border-2 border-gray-600" : ""
            // } mb-[-10px]`}
          >
            <div className="flex flex-wrap gap-2">
              {selectedFiles.map((file, index) => (
                <div key={index} className="relative">
                  <button
                    onClick={() => removeFile(index)}
                    className="absolute -top-3 -left-3 p-1 text-gray-600 dark:text-gray-300"
                    aria-label="Remove file"
                  >
                    <IoCloseCircleSharp size={18} />
                  </button>
                  <FilePreview file={file} />
                </div>
              ))}
            </div>
            <div className="text-sm text-gray-600 dark:text-gray-300 mt-2">
              {selectedFiles.length} file{selectedFiles.length !== 1 ? "s" : ""}{" "}
              added
            </div>
          </div>
        )}
        <form
          ref={dropZoneRef}
          onSubmit={handleSubmit}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`w-full flex flex-col sm:flex-row items-stretch sm:items-center justify-between border-2 
        ${
          isDragging
            ? "border-gray-300 dark:border-gray-600"
            : "border-gray-300 dark:border-gray-600"
        } 
        rounded-2xl ${themeClasses} 
        ${
          selectedFiles.length > 0 ? "rounded-b-2xl border-t-0" : "rounded-2xl"
        } 
        relative max-w-full`}
          // className={`w-full flex flex-col sm:flex-row items-stretch sm:items-center justify-between border-2 ${
          //   isDragging
          //     ? "border-gray-300 dark:border-gray-600"
          //     : "border-gray-300 dark:border-gray-600"
          // } rounded-2xl ${themeClasses} ${
          //   selectedFiles.length > 0
          //     ? "rounded-b-2xl border-t-0"
          //     : "rounded-2xl"
          // } relative`}
        >
          <div className="flex items-center flex-grow px-4 py-2">
            <button
              type="button"
              onClick={handleFileSelectButton}
              // className="ml-2 mb-2 text-gray-400 dark:text-gray-500"
              className="mr-2 text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
              aria-label="Attach files"
            >
              <FaPaperclip size={23} />
            </button>
            <button
              type="button"
              onClick={handleWebSearch}
              className={`mr-2 transition-colors duration-200 ${
                isWebSearch
                  ? "text-blue-500 dark:text-blue-400 hover:text-blue-600 dark:hover:text-blue-300"
                  : "text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
              }`}
              aria-label="Web search"
              title={isWebSearch ? "Web search enabled" : "Web search disabled"}
            >
              <TbWorldSearch size={23} />
            </button>
            <button
              type="button"
              onClick={handleIMGGeneration}
              className={`mr-2 transition-colors duration-200 ${
                isIMGGeneration
                  ? "text-green-500 dark:text-green-400 hover:text-green-600 dark:hover:text-green-300"
                  : "text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
              }`}
              aria-label="Image generation"
              title={
                isIMGGeneration
                  ? "Image generation enabled"
                  : "Image generation disabled"
              }
            >
              <IoIosImages size={23} />
            </button>
            <textarea
              ref={textareaRef}
              value={question}
              onChange={handleQuestionChange}
              onKeyDown={handleKeyDown}
              placeholder="Start a new chat..."
              className={`flex-grow mx-2 p-1 mt-3 rounded-lg focus:border-white dark:focus:border-gray-200 focus:outline-none placeholder-gray-500 dark:placeholder-gray-400 ${themeClasses}`}
              style={{
                minHeight: "40px",
                maxHeight: "200px",
                overflowY: "auto",
                resize: "none",
                width: "100%",
                // width: "calc(100% - 60px)",
              }}
            />
          </div>
          <input
            type="file"
            multiple
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
          <button
            type="submit"
            className={`flex items-center justify-center p-1 mr-2 rounded-md ${
              isLoading
                ? "bg-stone-500 dark:bg-stone-400 hover:bg-stone-700 dark:hover:bg-stone-200"
                : isInputActive
                ? "bg-stone-500 dark:bg-stone-400 hover:bg-stone-700 dark:hover:bg-stone-200"
                : "bg-stone-500 dark:bg-stone-400 hover:bg-stone-700 dark:hover:bg-stone-200"
            } text-white dark:text-gray-500`}
            onClick={(e) => {
              if (isLoading) {
                handleStop();
                e.preventDefault(); // Prevent form submission if stopping
              }
            }}
          >
            {isLoading ? (
              <FaStopCircle size={20} color="#fff" />
            ) : (
              <SubmitIcon />
            )}
          </button>
        </form>
      </div>
    );
  }
);

export default SubmitHandler;

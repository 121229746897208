import React, { useEffect, useState } from "react";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { BsFillPersonFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import logo from "../Images/logo.png";

function Header() {
  const [theme, setTheme] = useState(null);
  useEffect(() => {
    const userTheme = localStorage.getItem("theme");
    if (userTheme) {
      setTheme(userTheme);
      document.documentElement.classList.add(userTheme);
    } else {
      setTheme("light");
      document.documentElement.classList.add("light");
    }
  }, []);

  return (
    <header className="bg-white dark:bg-black shadow">
      <nav className="container mx-auto flex items-center justify-between p-2">
        <div className="flex items-center mb-4 mt-4">
          <div className="w-10 h-10 rounded-full overflow-hidden mr-2">
            <img
              src={logo}
              alt="Logo"
              className="w-10 h-10 rounded-full overflow-hidden border text-gray-800"
            />
          </div>
          <Link href="/" className="text-2xl font-bold">
            Responxe
          </Link>
        </div>
        <div className="flex items-center space-x-4">
          <ul className="flex space-x-4">
            <li>
              <a href="/myPlan" className="hover:text-gray-500">
                Pricing
              </a>
            </li>
            <li>
              <a href="#features" className="hover:text-gray-500">
                Features
              </a>
            </li>
            <li>
              <a href="#benefits" className="hover:text-gray-500">
                Benefits
              </a>
            </li>
            <li>
              <a href="#comparison" className="hover:text-gray-500">
                Comparison
              </a>
            </li>
            <li>
              <a
                href="/login"
                className="bg-gray-800 text-white hover:bg-gray-950 hover:text-gray-100 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-700 px-3 py-3 rounded-md font-semibold"
                style={{ fontWeight: "bold" }}
              >
                Log in
              </a>
            </li>
            <li>
              <a
                href="/signup"
                className="bg-gray-800 text-white hover:bg-gray-950 hover:text-gray-100 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-700 px-3 py-3 rounded-md font-semibold"
                style={{ fontWeight: "bold" }}
              >
                Sign Up
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;

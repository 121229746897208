// avatarConfigs.js
import { genConfig } from "react-nice-avatar";

export const maleAvatarConfigs = [
  genConfig({
    sex: "man",
    faceColor: "#F9C9B6",
    earSize: "big",
    eyeStyle: "smile",
    noseStyle: "short",
    mouthStyle: "laugh",
    hairStyle: "normal",
    hairColor: "#2A1C16", // Dark brown/black hair
    shirtStyle: "hoody",
    shirtColor: "#36454F", // Dark gray
    hatStyle: "none",
    glassesStyle: "square",
    bgColor: "#93c77d",
  }),
  genConfig({
    sex: "man",
    faceColor: "#F9C9B6",
    earSize: "big",
    eyeStyle: "circle",
    noseStyle: "short",
    mouthStyle: "peace",
    hairStyle: "thick",
    hairColor: "#ffe599",
    shirtStyle: "hoody",
    shirtColor: "#36454F", // Dark gray
    hatStyle: "none",
    glassesStyle: "none",
    bgColor: "#ef7167",
  }),
  // Add more male avatars as desired...
];

export const femaleAvatarConfigs = [
  genConfig({
    sex: "woman",
    faceColor: "#F9C9B6",
    earSize: "small",
    eyeStyle: "circle",
    noseStyle: "short",
    mouthStyle: "smile",
    shirtStyle: "hoody",
    glassesStyle: "none",
    hairColor: "#ffe599",
    hairStyle: "womanShort",
    hatStyle: "none",
    shirtColor: "#F4D150",
    bgColor: "#7db8ed",
  }),
  genConfig({
    sex: "woman",
    faceColor: "#F9C9B6",
    earSize: "big",
    eyeStyle: "oval",
    noseStyle: "round",
    mouthStyle: "peace",
    shirtStyle: "short",
    glassesStyle: "round",
    hairColor: "#783f04",
    hairStyle: "womanLong",
    hatStyle: "none",
    shirtColor: "#F4D150",
    bgColor: "#E0DDFF",
  }),
  // Add more female avatars as desired...
];

import React, { useState, useEffect, useRef } from "react";
import { BsTrash3 } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import CodeBlockHandler from "./CodeBlockHandler";
import logo from "../Images/logo.png";
import Avatar from "react-nice-avatar";
import AutoGrowingTextArea from "./AutoGrowingTextArea";
import { FiCopy, FiCheck } from "react-icons/fi";

const ChatHistoryDisplay = ({
  chatHistory,
  editIndex,
  editedQuestion,
  setEditedQuestion,
  submitHandlerRef,
  setEditIndex,
  handleQuestionDisplayed,
  getFileIcon,
  chatId,
  setChatHistory,
  setError,
  fetchWithAuth,
  serverUrl,
  setCurrentModel,
  avatarConfig,
  gptVersion,
}) => {
  const chatContainerRef = useRef(null);
  const [disabledAutoScrollAnswers, setDisabledAutoScrollAnswers] = useState(
    new Set()
  );

  const currentAnswerRef = useRef(null);
  const isUserScrollingRef = useRef(false);
  const [loadingImages, setLoadingImages] = useState({});
  const [copiedQuestion, setCopiedQuestion] = useState(null);
  const [editAreaRef, setEditAreaRef] = useState(null);

  // Handle auto-scrolling
  // useEffect(() => {
  //   if (!chatContainerRef.current || !chatHistory.length) return;

  //   const lastChat = chatHistory[chatHistory.length - 1];
  //   const chatContainer = chatContainerRef.current;

  //   // Skip auto-scroll if user is scrolling and this answer is in disabled set
  //   if (
  //     isUserScrollingRef.current &&
  //     disabledAutoScrollAnswers.has(lastChat.id)
  //   ) {
  //     return;
  //   }

  //   // Only auto-scroll for new messages or non-disabled streaming messages
  //   if (currentAnswerRef.current !== lastChat) {
  //     currentAnswerRef.current = lastChat;
  //     isUserScrollingRef.current = false; // Reset scrolling state for new messages
  //     setDisabledAutoScrollAnswers(new Set()); // Reset disabled set for new messages
  //   }
  //   // Use requestAnimationFrame for smooth scrolling
  //   let animationFrameId;
  //   const smoothScroll = () => {
  //     if (!chatContainer || isUserScrollingRef.current) return;

  //     const scrollTarget =
  //       chatContainer.scrollHeight - chatContainer.clientHeight;
  //     const currentScroll = chatContainer.scrollTop;

  //     // If we're already at the bottom, no need to scroll
  //     if (Math.abs(scrollTarget - currentScroll) < 1) return;

  //     chatContainer.scrollTop = scrollTarget;
  //     animationFrameId = requestAnimationFrame(smoothScroll);
  //     return () => {
  //       if (animationFrameId) {
  //         cancelAnimationFrame(animationFrameId);
  //       }
  //     };
  //   };

  //   animationFrameId = requestAnimationFrame(smoothScroll);
  //   // const scrollTimeout = setTimeout(() => {
  //   //   if (chatContainer && !isUserScrollingRef.current) {
  //   //     chatContainer.scrollTop = chatContainer.scrollHeight;
  //   //   }
  //   // }, 100);

  //   // return () => clearTimeout(scrollTimeout);
  // }, [chatHistory, disabledAutoScrollAnswers]);

  // Handle auto-scrolling
  useEffect(() => {
    if (!chatContainerRef.current || !chatHistory.length) return;

    const lastChat = chatHistory[chatHistory.length - 1];
    const chatContainer = chatContainerRef.current;

    // Reset scroll state for new messages
    if (currentAnswerRef.current?.id !== lastChat.id) {
      currentAnswerRef.current = lastChat;
      isUserScrollingRef.current = false; // Reset scrolling state for new messages
      setDisabledAutoScrollAnswers(new Set()); // Reset disabled set for new messages
    }

    // Skip auto-scroll if user is scrolling and this answer is in disabled set
    if (
      isUserScrollingRef.current &&
      disabledAutoScrollAnswers.has(lastChat.id)
    ) {
      return;
    }

    // Only auto-scroll for new messages or non-disabled streaming messages
    // if (currentAnswerRef.current !== lastChat) {
    //   currentAnswerRef.current = lastChat;
    //   isUserScrollingRef.current = false; // Reset scrolling state for new messages
    //   setDisabledAutoScrollAnswers(new Set()); // Reset disabled set for new messages
    // }

    let animationFrameId;

    const smoothScroll = () => {
      if (!chatContainer || isUserScrollingRef.current) {
        return;
      }

      const scrollTarget =
        chatContainer.scrollHeight - chatContainer.clientHeight;
      const currentScroll = chatContainer.scrollTop;

      // If we're already at the bottom, no need to scroll
      if (Math.abs(scrollTarget - currentScroll) < 1) {
        return;
      }

      chatContainer.scrollTop = scrollTarget;
      animationFrameId = requestAnimationFrame(smoothScroll);
    };

    animationFrameId = requestAnimationFrame(smoothScroll);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [chatHistory, disabledAutoScrollAnswers]);

  // Handle scroll events
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;

    const handleScroll = (e) => {
      if (!chatHistory.length) return;
      const lastChat = chatHistory[chatHistory.length - 1];
      const isScrollingUp = e.deltaY < 0;

      if (isScrollingUp && lastChat.status === "streaming") {
        isUserScrollingRef.current = true;
        setDisabledAutoScrollAnswers((prev) => new Set([...prev, lastChat.id]));
      }
    };

    const handleScrollEnd = () => {
      // Check if user has scrolled to bottom
      if (
        chatContainer.scrollHeight - chatContainer.scrollTop ===
        chatContainer.clientHeight
      ) {
        isUserScrollingRef.current = false;
      }
    };
    chatContainer.addEventListener("wheel", handleScroll);
    chatContainer.addEventListener("scroll", handleScrollEnd);

    return () => {
      chatContainer.removeEventListener("wheel", handleScroll);
      chatContainer.removeEventListener("scroll", handleScrollEnd);
    };
  }, [chatHistory]);

  // Handle scroll events
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;

    const handleScroll = (e) => {
      if (!chatHistory.length) return;
      const lastChat = chatHistory[chatHistory.length - 1];
      const isScrollingUp = e.deltaY < 0;

      if (isScrollingUp && lastChat.status === "streaming") {
        isUserScrollingRef.current = true;
        setDisabledAutoScrollAnswers((prev) => new Set([...prev, lastChat.id]));
      }
    };

    const handleScrollEnd = () => {
      // Check if user has scrolled to bottom
      if (
        chatContainer.scrollHeight - chatContainer.scrollTop ===
        chatContainer.clientHeight
      ) {
        isUserScrollingRef.current = false;
      }
    };

    chatContainer.addEventListener("wheel", handleScroll);
    chatContainer.addEventListener("scroll", handleScrollEnd);

    return () => {
      chatContainer.removeEventListener("wheel", handleScroll);
      chatContainer.removeEventListener("scroll", handleScrollEnd);
    };
  }, [chatHistory]);
  // useEffect(() => {
  //   const chatContainer = chatContainerRef.current;
  //   if (!chatContainer) return;
  //   const handleScroll = (e) => {
  //     if (!chatHistory.length) return;

  //     const lastChat = chatHistory[chatHistory.length - 1];
  //     const isScrollingUp = e.deltaY < 0;

  //     if (isScrollingUp && lastChat.status === "streaming") {
  //       // Only disable auto-scroll for the current streaming message
  //       setDisabledAutoScrollAnswers((prev) => new Set([...prev, lastChat.id]));
  //     }
  //   };

  //   chatContainer.addEventListener("wheel", handleScroll);
  //   return () => chatContainer.removeEventListener("wheel", handleScroll);
  // }, [chatHistory]);

  // Reset disabled answers when new message arrives
  // useEffect(() => {
  //   if (chatHistory.length > 0) {
  //     const lastChat = chatHistory[chatHistory.length - 1];
  //     if (currentAnswerRef.current !== lastChat) {
  //       // Completely reset the disabled set for new messages
  //       setDisabledAutoScrollAnswers(new Set());
  //       currentAnswerRef.current = lastChat;
  //     }
  //   }
  // }, [chatHistory]);

  const copyToClipboard = (questionContent) => {
    navigator.clipboard.writeText(questionContent).then(() => {
      setCopiedQuestion(questionContent);
      setTimeout(() => setCopiedQuestion(null), 2000);
    });
  };

  // Remove the cursor logic from renderAnswer
  const renderAnswer = (chat, index) => {
    if (!chat.answer && chat.status === "pending") {
      return (
        <div className="flex items-center space-x-2 text-gray-500 dark:text-gray-400">
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-500 dark:border-gray-400"></div>
          <span>Processing your request...</span>
        </div>
      );
    }

    if (chat.status === "aborted") {
      return (
        <div className="text-yellow-600 dark:text-yellow-400">
          {renderContent(chat.answer, index) || "Request was cancelled"}
        </div>
      );
    }

    return renderContent(chat.answer, index);
  };

  // Get the last chat entry to determine cursor state
  const lastChat = chatHistory[chatHistory.length - 1];
  const showCursor =
    lastChat?.status === "streaming" || lastChat?.status === "pending";

  const handleDeleteChat = async (index) => {
    try {
      const response = await fetchWithAuth(
        `${serverUrl}/api/delete-chat-entry`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chatId,
            index,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete chat entry");
      }

      const data = await response.json();
      setChatHistory(data.chatHistory);
    } catch (error) {
      console.error("Error deleting chat entry:", error);
      setError("Failed to delete chat entry");
    }
  };

  const handleClickOutside = (event) => {
    if (editAreaRef && !editAreaRef.contains(event.target)) {
      setEditIndex(null);
      setEditedQuestion("");
      // You can also use setCurrentModel(null) if applicable
    }
  };

  useEffect(() => {
    if (editAreaRef) {
      // Listen for clicks outside the ref
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editAreaRef]);

  const handleEditChat = (index, question, model) => {
    setEditIndex(index);
    setEditedQuestion(question);
    if (setCurrentModel) {
      setCurrentModel(model);
    }
  };

  const handleSaveEdit = async (e, editedQuestion, index) => {
    e.preventDefault();

    // Immediately update the UI by modifying the chat history
    setChatHistory((prevHistory) => {
      const updatedHistory = [...prevHistory];
      updatedHistory[index] = {
        ...updatedHistory[index],
        question: editedQuestion,
        status: "pending", // Add pending status while waiting for response
      };
      return updatedHistory;
    });

    // Reset edit state immediately
    setEditIndex(null);
    setEditedQuestion("");

    try {
      const newAnswer = await submitHandlerRef.current.handleSubmit(
        e,
        editedQuestion,
        index
      );

      console.log("newAnswer in handleSaveEdit: ", newAnswer);

      const response = await fetchWithAuth(`${serverUrl}/api/edit-chat-entry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chatId,
          index,
          newQuestion: editedQuestion,
          newAnswer: newAnswer,
          apiModel: chatHistory[index].apiModel,
          modelName: chatHistory[index].modelName,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update chat entry");
      }

      const data = await response.json();
      setChatHistory(data.chatHistory);
      // setEditIndex(null);
    } catch (error) {
      console.error("Error updating chat entry:", error);
      setError("Failed to update chat entry");
      // Revert the chat history if there's an error
      setChatHistory((prevHistory) => {
        const updatedHistory = [...prevHistory];
        updatedHistory[index] = {
          ...updatedHistory[index],
          status: "error",
        };
        return updatedHistory;
      });
    }
  };

  const renderContent = (content, chatIndex) => {
    if (typeof content !== "string") return content;

    // Split content into text and image sections
    const parts = content.split(/!\[.*?\]\((.*?)\)/);

    return parts
      .map((part, index) => {
        if (index % 2 === 0) {
          // Text content
          if (!part.trim()) return null;
          return (
            <CodeBlockHandler
              key={`text-${chatIndex}-${index}`}
              answer={part}
            />
          );
        } else {
          // Image URL
          const imageKey = `${chatIndex}-${index}`;
          return (
            <div key={`image-${imageKey}`} className="my-4 flex justify-center">
              {loadingImages[imageKey] && (
                <div className="flex justify-center items-center h-16">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                </div>
              )}
              <img
                src={part}
                alt="Generated illustration"
                className={`w-96 h-auto rounded-lg shadow-lg transition-opacity duration-200 ${
                  loadingImages[imageKey] ? "opacity-0" : "opacity-100"
                }`}
                style={{
                  maxWidth: "384px", // equivalent to w-96
                  objectFit: "contain",
                }}
                loading="lazy"
                onLoad={() => {
                  setLoadingImages((prev) => ({ ...prev, [imageKey]: false }));
                }}
                onError={(e) => {
                  console.error("Failed to load image:", part);
                  setLoadingImages((prev) => ({ ...prev, [imageKey]: false }));
                  e.target.style.display = "none";
                }}
              />
            </div>
          );
        }
      })
      .filter(Boolean); // Remove null entries
  };

  return (
    <div
      className="space-y-4 max-h-[calc(100vh-200px)] overflow-y-auto"
      ref={chatContainerRef}
    >
      {chatHistory.map((chat, chatIndex) => (
        <div key={chatIndex} className="p-4 relative">
          <div className="flex flex-col bg-gray-100 dark:bg-gray-800 p-2 rounded-lg">
            {editIndex === chatIndex ? (
              <div ref={setEditAreaRef} onClick={(e) => e.stopPropagation()}>
                <div className="flex items-start gap-3">
                  {avatarConfig ? (
                    <Avatar
                      style={{ width: "24px", height: "24px" }}
                      {...avatarConfig}
                    />
                  ) : (
                    <div className="flex items-center justify-center h-6 w-6 rounded-full bg-gray-800 text-white text-xs font-bold">
                      U
                    </div>
                  )}
                  <AutoGrowingTextArea
                    value={editedQuestion}
                    onChange={(e) => setEditedQuestion(e.target.value)}
                  />
                </div>
                <button
                  onClick={(e) => handleSaveEdit(e, editedQuestion, chatIndex)}
                  className="mt-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
                >
                  Save
                </button>
              </div>
            ) : (
              <div className="flex items-start gap-3">
                {avatarConfig ? (
                  <Avatar
                    style={{ width: "26px", height: "26px" }}
                    {...avatarConfig}
                  />
                ) : (
                  <div className="flex items-center justify-center h-6 w-6 rounded-full bg-gray-800 text-white text-xs font-bold">
                    U
                  </div>
                )}
                <pre
                  onLoad={handleQuestionDisplayed}
                  className="flex-1 font-sans text-[16px] leading-[1.5] text-gray-800 dark:text-gray-200 whitespace-pre-wrap"
                  style={{ fontFamily: '"Inter", sans-serif' }}
                >
                  {chat.question || chat.content}
                </pre>
              </div>
            )}
            <div className="flex justify-end items-center space-x-2 mt-2 pt-2">
              <button
                onClick={() => copyToClipboard(chat.question)}
                className="flex items-center text-gray-400 dark:text-gray-300 hover:text-gray-700 focus:outline-none"
                aria-label="Copy code to clipboard"
              >
                {copiedQuestion === chat.question ? (
                  <FiCheck className="w-4 h-4 text-green-500" />
                ) : (
                  <FiCopy className="w-4 h-4" />
                )}
              </button>
              <FiEdit2
                className="cursor-pointer text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-600"
                onClick={() =>
                  handleEditChat(
                    chatIndex,
                    chat.question || chat.content,
                    chat.apiModel
                  )
                }
              />

              <BsTrash3
                className="cursor-pointer text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-600"
                onClick={() => handleDeleteChat(chatIndex)}
              />
            </div>
          </div>
          {chat.files && chat.files.length > 0 && (
            <div>
              <h4 className="font-bold mt-2 text-[18px] leading-[1.5] text-gray-800 dark:text-gray-200">
                Attached Files:
              </h4>
              <ul className="font-sans text-[16px] leading-[1.5] text-gray-800 dark:text-gray-200">
                {chat.files.map((file, fileIndex) => (
                  <li key={fileIndex}>
                    {getFileIcon(file.type)} {file.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="mt-5 p-2">
            <div className="flex items-start gap-3">
              <img
                src={logo}
                alt="Logo"
                className="w-6 h-6 rounded-full overflow-hidden border flex-shrink-0"
              />
              <div className="prose dark:prose-invert max-w-none w-full">
                {renderAnswer(chat, chatIndex)}
              </div>
            </div>
            {chat.modelName && (
              <div className="flex justify-end">
                <span className="text-xs text-gray-500 dark:text-gray-400">
                  By {chat.modelName}
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatHistoryDisplay;

import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import CountrySelect from "./MyPlan/CountrySelect";

function Signup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false); // New state for terms acceptance
  const [showModal, setShowModal] = useState(false);
  const [emailToResend, setEmailToResend] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const handleResendVerification = async () => {
    try {
      const response = await axios.post(
        `${serverUrl}/api/resend-verification`,
        {
          email: emailToResend,
        }
      );
      const message =
        typeof response.data === "string"
          ? response.data
          : response.data.message;
      alert(
        message || "Verification email sent again. Please check your inbox."
      );
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data ||
        "An error occurred while trying to resend the verification email.";
      alert(errorMessage);
    }
    setShowModal(false); // Hide modal after sending email
  };
  const resendVerificationModal = showModal && (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 w-96 shadow-lg rounded-md bg-white text-black dark:text-white dark:bg-black">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-black dark:text-white">
            Resend Verification Email
          </h3>
          <div className="mt-2 px-4 py-3">
            <input
              type="email"
              className="px-4 py-2 border-2 border-gray-300 w-full rounded-md"
              placeholder="Enter your email"
              value={emailToResend}
              onChange={(e) => setEmailToResend(e.target.value)}
            />
          </div>
          <div className="items-center px-4 py-3">
            <button
              className="px-4 py-2 bg-gray-800 text-white hover:bg-gray-950 hover:text-gray-100 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-700 text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
              // className="w-full bg-gray-800 text-white hover:bg-gray-950 hover:text-gray-100 dark:text-black dark:bg-gray-300 dark:hover:bg-gray-400 px-3 py-3 rounded-md font-semibold focus:outline-none"
              onClick={handleResendVerification}
            >
              Resend Email
            </button>
            <button
              className="mt-3 px-4 py-2 bg-gray-800 text-white hover:bg-gray-950 hover:text-gray-100 dark:text-black dark:bg-gray-300 dark:hover:bg-gray-400 text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-300"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Your existing state declarations here...
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    // Clear any existing country errors
    setErrors((prev) => ({ ...prev, country: "" }));
  };

  const formatPhoneWithCountryCode = (phone) => {
    if (!selectedCountry || !phone) return phone;
    const digitsOnly = phone.replace(/\D/g, "");
    return `${selectedCountry.code}${digitsOnly}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true); // Mark as submitted to trigger validation checks
    setPromoError("");

    // First validate all required fields
    const newErrors = {};

    // Add country validation
    if (!selectedCountry) {
      newErrors.country = "Please select your country";
    }

    if (!firstName.trim()) newErrors.firstName = "First name is required";
    if (!lastName.trim()) newErrors.lastName = "Last name is required";
    if (!email.trim()) newErrors.email = "Email is required";
    if (!phoneNumber.trim()) newErrors.phoneNumber = "Phone number is required";
    if (!password.trim()) newErrors.password = "Password is required";

    // Validate email format
    if (!email.includes("@")) {
      alert("Please enter a valid email address");
      return;
    }

    // Validate phone number format
    if (!isValidPhone(phoneNumber)) {
      setErrors({
        ...newErrors,
        phoneNumber: "Please enter a valid phone number",
      });
      return;
    }

    // Check password requirements
    const passwordReqs = isValidPassword(password);
    if (
      password &&
      (!passwordReqs.length ||
        !passwordReqs.upperCase ||
        !passwordReqs.lowerCase ||
        !passwordReqs.number)
    ) {
      newErrors.password = "Password must meet all requirements";
    }

    // Check if terms and policies are accepted
    if (!acceptTerms) {
      return;
      // newErrors.acceptTerms =
      //   "Please accept the Terms of Use and Privacy Policy";
    }

    // If there are any errors, display them and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      alert("Please fill in all required fields correctly");
      return;
    }

    try {
      console.log("sending to sign up");
      const formattedPhone = formatPhoneWithCountryCode(phoneNumber);
      const response = await axios.post(`${serverUrl}/api/signup`, {
        firstName,
        lastName,
        phoneNumber: formattedPhone,
        email,
        password,
        promoCode: promoCode.trim(),
        country: selectedCountry.name,
        countryCode: selectedCountry.code,
      });
      alert(
        response.data.message ||
          "Signup successful! Please check your email to verify your account."
      );
      navigate("/login"); // Adjust according to your route settings
    } catch (error) {
      if (error.response?.data?.promoError) {
        setPromoError(error.response.data.promoError);
      } else {
        alert(error.response?.data || "An error occurred during signup.");
      }
    }
  };

  const emailNotValid = submitted && !email.includes("@");
  const passwordNotValid = submitted && password.trim().length < 3;
  const nameNotValid = submitted && (!firstName.trim() || !lastName.trim());
  const phoneNotValid = submitted && !phoneNumber.trim();

  // Password validation function
  const isValidPassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);

    const requirements = {
      length: password.length >= minLength,
      upperCase: hasUpperCase,
      lowerCase: hasLowerCase,
      number: hasNumber,
    };

    return requirements;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Phone validation function
  const isValidPhone = (phone) => {
    // Extract only digits from the phone number
    const digitsOnly = phone.replace(/\D/g, "");

    // Valid phone numbers can be:
    // - Between 7 and 15 digits (international standard)
    // - May contain spaces, dashes, parentheses
    // - May start with + for international format
    const phoneRegex = /^\+?[\d\s-()]{7,20}$/;

    return (
      phoneRegex.test(phone) &&
      digitsOnly.length >= 7 &&
      digitsOnly.length <= 15
    );
  };

  const validateForm = () => {
    const newErrors = {};

    // Required field validation
    if (!firstName.trim()) newErrors.firstName = "First name is required";
    if (!lastName.trim()) newErrors.lastName = "Last name is required";
    if (!email.trim()) newErrors.email = "Email is required";
    if (!phoneNumber.trim()) newErrors.phoneNumber = "Phone number is required";
    if (!password.trim()) newErrors.password = "Password is required";

    // Email format validation
    if (email.trim() && !isValidEmail(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    // Phone number validation
    if (phoneNumber.trim() && !isValidPhone(phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid phone number (7-15 digits)";
    }

    // Enhanced password validation
    if (password.trim()) {
      const passwordRequirements = isValidPassword(password);
      const passwordErrors = [];

      if (!passwordRequirements.length) {
        passwordErrors.push("at least 8 characters");
      }
      if (!passwordRequirements.upperCase) {
        passwordErrors.push("1 uppercase letter");
      }
      if (!passwordRequirements.lowerCase) {
        passwordErrors.push("1 lowercase letter");
      }
      if (!passwordRequirements.number) {
        passwordErrors.push("1 number");
      }

      if (passwordErrors.length > 0) {
        newErrors.password = `Password must contain: ${passwordErrors.join(
          ", "
        )}`;
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Helper function to show password requirements while typing
  const getPasswordStrength = () => {
    if (!password) return null;

    const requirements = isValidPassword(password);
    const metRequirements = Object.values(requirements).filter(Boolean).length;

    // Return different colors based on password strength
    if (metRequirements === 4) return "text-green-500";
    if (metRequirements >= 2) return "text-yellow-500";
    return "text-red-500";
  };
  return (
    <div className="flex justify-center items-center min-h-screen bg-white dark:bg-gray-800">
      <div className="bg-gray-50 dark:bg-black p-8 rounded-lg shadow-md w-full max-w-sm border border-cyan-50 drak:border-cyan-950">
        <h2 className="text-gray-800 dark:text-white text-2xl font-bold mb-6 text-center">
          Signup
        </h2>
        <form
          onSubmit={handleSubmit}
          className="space-y-4"
          action="/signup"
          method="POST"
        >
          <div>
            <div className="flex gap-4">
              <div className="flex-1 py-2">
                <input
                  placeholder="First Name"
                  className={`bg-white text-black dark:text-white dark:bg-gray-800 border-2 ${
                    errors.firstName && !firstName
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-3 w-full rounded-md focus:border-purple-500`}
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="flex-1 py-2">
                <input
                  placeholder="Last Name"
                  className={`bg-white text-black dark:text-white dark:bg-gray-800 border-2 ${
                    errors.lastName && !lastName
                      ? "border-red-500"
                      : "border-gray-300"
                  } p-3 w-full rounded-md focus:border-purple-500`}
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>

            <input
              placeholder="Email"
              className="bg-white text-black dark:text-white dark:bg-gray-800 border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              className={`bg-white text-black dark:text-white dark:bg-gray-800 border-2 ${
                errors.password ? "border-red-500" : "border-gray-300"
              } p-3 w-full rounded-md focus:border-purple-500`}
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {password && (
              <div className={`text-sm mt-1 ${getPasswordStrength()}`}>
                Password requirements:
                <ul className="list-disc ml-5">
                  <li
                    className={
                      isValidPassword(password).length
                        ? "text-green-500"
                        : "text-red-500"
                    }
                  >
                    Minimum 8 characters
                  </li>
                  <li
                    className={
                      isValidPassword(password).upperCase
                        ? "text-green-500"
                        : "text-red-500"
                    }
                  >
                    At least 1 uppercase letter
                  </li>
                  <li
                    className={
                      isValidPassword(password).lowerCase
                        ? "text-green-500"
                        : "text-red-500"
                    }
                  >
                    At least 1 lowercase letter
                  </li>
                  <li
                    className={
                      isValidPassword(password).number
                        ? "text-green-500"
                        : "text-red-500"
                    }
                  >
                    At least 1 number
                  </li>
                </ul>
              </div>
            )}
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>

          <div className="mb-4">
            <div className="flex gap-2 items-center">
              <div className="flex-grow">
                <CountrySelect
                  selectedCountry={selectedCountry}
                  onSelect={handleCountrySelect}
                  error={errors.country}
                />
              </div>
            </div>
          </div>

          <div>
            <div className="flex gap-2">
              <div className="w-24 flex-shrink-0">
                <div className="border-2 border-gray-300 p-3 rounded-md bg-white text-black dark:text-white dark:bg-gray-800 text-center">
                  {selectedCountry ? (
                    <span className="font-medium">{selectedCountry.code}</span>
                  ) : (
                    <span className="text-gray-400">Code</span>
                  )}
                </div>
              </div>

              <input
                className={`bg-white text-black dark:text-white dark:bg-gray-800 border-2 ${
                  errors.phoneNumber && !phoneNumber
                    ? "border-red-500"
                    : "border-gray-300"
                } p-3 w-full rounded-md focus:border-purple-500`}
                placeholder="Phone Number"
                type="tel"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  if (!e.target.value) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      phoneNumber: "Phone number is required",
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      phoneNumber: "",
                    }));
                  }
                }}
              />
            </div>
            {errors.phoneNumber && (
              <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>
            )}
          </div>
          <div>
            <input
              className="bg-white text-black dark:text-white dark:bg-gray-800 border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
              placeholder="Promo Code (optional)"
              type="text"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            {promoError && (
              <p className="text-red-500 text-sm mt-1">{promoError}</p>
            )}
          </div>

          {/* Terms and Policies Checkbox */}
          <div>
            {!acceptTerms && submitted && (
              <p className="text-red-500 text-sm mt-1">
                Please accept the terms and privacy policy
              </p>
            )}
            <div className="flex items-center">
              <input
                type="checkbox"
                id="accept-terms"
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
                className={`w-4 h-4 mr-2 rounded border ${
                  !acceptTerms && submitted
                    ? "border-2 border-red-500"
                    : "border-gray-300"
                }`}
              />
              <label
                htmlFor="accept-terms"
                className="text-gray-600 dark:text-gray-400"
              >
                I accept the{" "}
                <Link to="/terms" className="text-blue-600 hover:underline">
                  Terms of Use
                </Link>{" "}
                and{" "}
                <Link to="/privacy" className="text-blue-600 hover:underline">
                  Privacy Policy
                </Link>
                .
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-gray-800 text-white hover:bg-gray-950 hover:text-gray-100 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-700 px-3 py-3 rounded-md font-semibold focus:outline-none mt-4"
          >
            Signup
          </button>
          <div className="text-center">
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                setShowModal(true);
              }}
              className="text-blue-600 hover:underline"
            >
              Resend Verification Email
            </a>
          </div>
        </form>
        <p className="text-gray-800 dark:text-white mt-4 text-center">
          Already a member?
          <Link to="/login" className="text-blue-600 hover:underline">
            {" "}
            Log in
          </Link>
        </p>
        {showModal && resendVerificationModal}
      </div>
    </div>
  );
}

export default Signup;

import React from "react";

const CheckoutSummary = ({
  selectedPlan,
  couponCode,
  setCouponCode,
  validateCoupon,
  couponError,
  discount,
  setShowCheckoutSummary,
  handleProceedToStripe,
}) => {
  const subtotal = selectedPlan.price;
  const tax = ((subtotal - discount) * 0.08).toFixed(2);
  const total = (parseFloat(subtotal) + parseFloat(tax) - discount).toFixed(2);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md p-6">
        <h2 className="text-2xl font-bold text-center mb-6">Checkout</h2>
        <div className="space-y-4">
          <div className="space-y-2">
            <h3 className="font-medium">Plan Details</h3>
            <div className="flex justify-between">
              <span>{selectedPlan.name}</span>
              <span>${selectedPlan.price}</span>
            </div>
            {selectedPlan.description.map((desc, index) => (
              <div
                key={index}
                className="text-sm text-gray-600 dark:text-gray-400"
              >
                {desc}
              </div>
            ))}
          </div>

          <div className="space-y-2">
            <h3 className="font-medium">Have a coupon?</h3>
            <div className="flex space-x-2">
              <input
                placeholder="Enter Coupon code"
                // className="flex-1 px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600"
                className="bg-white text-black dark:text-white dark:bg-gray-800 border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
                type="text"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  console.log("Apply coupon button clicked");
                  validateCoupon();
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Apply
              </button>
            </div>
            {couponError && (
              <div className="text-red-500 text-sm">{couponError}</div>
            )}
            {discount > 0 && (
              <div className="text-green-600 dark:text-green-400">
                Discount applied: -${discount.toFixed(2)}
              </div>
            )}
          </div>

          <div className="space-y-2 border-t pt-2">
            <div className="flex justify-between">
              <span>Subtotal</span>
              <span>${subtotal}</span>
            </div>
            {discount > 0 && (
              <div className="flex justify-between text-green-600">
                <span>Discount</span>
                <span>-${discount.toFixed(2)}</span>
              </div>
            )}
            {/* <div className="flex justify-between">
                  <span>Stripe Fee</span>
                  <span>${stripeFee}</span>
                </div> */}
            <div className="flex justify-between">
              <span>Estimated Tax</span>
              <span>${tax}</span>
            </div>
            <div className="flex justify-between font-bold border-t pt-2">
              <span>Total</span>
              <span>${total}</span>
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-6">
          <button
            onClick={() => setShowCheckoutSummary(false)}
            className="px-4 py-2 border rounded-md hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            Cancel
          </button>
          <button
            onClick={handleProceedToStripe}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Complete Purchase
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSummary;

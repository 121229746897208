import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Success() {
  const navigate = useNavigate();

  useEffect(() => {
    // Optionally, fetch updated user data or show a success message
    // For now, we'll redirect the user to the main page after a short delay
    const timer = setTimeout(() => {
      navigate("/mainPage");
    }, 5000); // 5 seconds delay

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white dark:bg-gray-900">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-200 mb-4">
        Payment Successful!
      </h1>
      <p className="text-gray-700 dark:text-gray-300">
        Thank you for your purchase!
      </p>
      <p className="mt-2 text-gray-500 dark:text-gray-400">
        You will be redirected to Responxe.com shortly.
      </p>
    </div>
  );
}

export default Success;

import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "./ThemeContext";
import ThemeSwitcher from "./ThemeSwitcher";
import { IoCloseCircleSharp } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useClickOutside from "./useClickOutside"; // Adjust the path as necessary

import Avatar from "react-nice-avatar";
import {
  maleAvatarConfigs,
  femaleAvatarConfigs,
} from "../components/Setting/avatarConfigs";

const Settings = ({
  setShowSettingsModal,
  userEmail,
  serverUrl,
  fetchWithAuth,
  avatarConfig, // Add this prop
  setAvatarConfig, // Add this prop
}) => {
  const [email, setEmail] = useState(userEmail);
  const [planType, setPlanType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showModal, setShowModal] = useState(false);

  // State for currently selected avatar config
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const { theme } = useTheme();
  // Create a ref for the modal container
  const modalRef = useRef(null);

  // Handler to close the modal
  const handleCloseModal = () => {
    setShowSettingsModal(false);
  };
  // Use the custom hook to detect clicks outside the modal
  useClickOutside(modalRef, handleCloseModal);

  // Update useEffect to handle avatarConfig prop
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetchWithAuth(`${serverUrl}/api/user-data`);
        if (response.ok) {
          const userData = await response.json();
          setPlanType(userData.planType);
          setPhoneNumber(userData.phoneNumber || "");

          // If user already has a saved avatar, load it
          if (userData.avatarConfig) {
            setSelectedAvatar(userData.avatarConfig);
            if (setAvatarConfig) {
              setAvatarConfig(userData.avatarConfig);
            }
            // setAvatarConfig(userData.avatarConfig); // Update parent state too
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [fetchWithAuth, serverUrl, setAvatarConfig]);

  const handleSaveAvatar = async (config) => {
    try {
      const response = await fetchWithAuth(`${serverUrl}/api/update-avatar`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ avatarConfig: config }),
      });

      if (response.ok) {
        setSelectedAvatar(config);
        if (setAvatarConfig) {
          setAvatarConfig(config); // Update parent st
        }
        // setAvatarConfig(config); // Update parent state
        alert("Avatar updated successfully");
      } else {
        alert("Failed to update avatar");
      }
    } catch (error) {
      console.error("Error updating avatar:", error);
      alert("An error occurred while updating avatar");
    }
  };

  // Handle unsubscribing (cancels subscription at period end)
  const handleUnsubscribe = async () => {
    try {
      const res = await fetchWithAuth(`${serverUrl}/api/cancel-subscription`, {
        method: "POST",
      });
      if (!res.ok) {
        throw new Error("Failed to cancel subscription");
      }
      const data = await res.json();
      // e.g. { message: "Subscription is set to cancel at end of billing period." }
      alert(data.message || "Subscription cancel request successful.");
    } catch (err) {
      console.error("Unsubscribe error:", err);
      alert(`Error unsubscribing: ${err.message}`);
    }
  };

  const unsubscribeModal = showModal && (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 w-96 shadow-lg rounded-md bg-white text-black dark:text-white dark:bg-black">
        <div className="mt-3 text-center">
          <h2 className="text-lg mb-3 leading-6 font-medium text-black dark:text-white">
            Unsubscribe{" "}
          </h2>
          <p>
            Please confirm you are unsubscribing by clicking on Unsubscribe
            button
          </p>
          <div className="items-center px-4 py-3">
            <button
              className="px-4 py-2 bg-gray-800 text-white hover:bg-gray-950 hover:text-gray-100 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-700 text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
              // className="w-full bg-gray-800 text-white hover:bg-gray-950 hover:text-gray-100 dark:text-black dark:bg-gray-300 dark:hover:bg-gray-400 px-3 py-3 rounded-md font-semibold focus:outline-none"
              onClick={handleUnsubscribe}
            >
              Unsubscribe
            </button>
            <button
              className="mt-3 px-4 py-2 bg-gray-800 text-white hover:bg-gray-950 hover:text-gray-100 dark:text-black dark:bg-gray-300 dark:hover:bg-gray-400 text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-300"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50`}
    >
      <div
        ref={modalRef}
        className={`bg-white dark:bg-gray-800 text-gray-800 dark:text-white p-6 rounded-lg shadow-xl max-w-md w-full relative ${theme}`}
      >
        <button
          onClick={handleCloseModal}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white"
          aria-label="Close Settings"
        >
          <IoCloseCircleSharp size={24} />
        </button>
        <h1 className="text-2xl font-bold mb-6">Settings</h1>
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Email</h2>
          <p className="text-gray-700 dark:text-gray-300">{email}</p>
        </div>

        {/* Avatar Selection */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Profile Picture</h2>
          <h3 className="font-bold">Choose an avatar below:</h3>

          <div className="flex gap-4 mt-2 flex-wrap">
            <div className="flex gap-4 mt-2 flex-wrap">
              {maleAvatarConfigs.map((config, index) => (
                <div
                  key={`male-${index}`}
                  className={`cursor-pointer p-2 rounded ${
                    JSON.stringify(selectedAvatar) === JSON.stringify(config)
                      ? "border-2 border-blue-500"
                      : "border border-transparent"
                  }`}
                  onClick={() => handleSaveAvatar(config)}
                >
                  <Avatar
                    style={{ width: "60px", height: "60px" }}
                    {...config}
                  />
                </div>
              ))}
            </div>
            {/* </div> */}

            {/* <div className="mt-4"> */}
            {/* <h3 className="font-bold">Female Avatars</h3> */}
            <div className="flex gap-4 mt-2 flex-wrap">
              {femaleAvatarConfigs.map((config, index) => (
                <div
                  key={`female-${index}`}
                  className={`cursor-pointer p-2 rounded ${
                    JSON.stringify(selectedAvatar) === JSON.stringify(config)
                      ? "border-2 border-blue-500"
                      : "border border-transparent"
                  }`}
                  onClick={() => handleSaveAvatar(config)}
                >
                  <Avatar
                    style={{ width: "60px", height: "60px" }}
                    {...config}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Plan Type</h2>
          <p className="text-gray-700 dark:text-gray-300">
            {planType || "Loading..."}
          </p>
          {planType && planType !== "free" && (
            <div>
              <p className="mt-1 text-gray-800 dark:text-white">
                You can
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowModal(true);
                  }}
                  className="text-blue-600 hover:underline"
                >
                  {" "}
                  unsubscribe{" "}
                </a>
                whenever you want
              </p>
            </div>
          )}
        </div>

        {/* Theme Switcher */}
        <div className="mb-6">
          <ThemeSwitcher className="text-xl font-semibold mb-2" />
        </div>
        {showModal && unsubscribeModal}
      </div>
    </div>
  );
};

export default Settings;

import React, { useState, useCallback } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FiCopy, FiCheck } from "react-icons/fi";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const CodeBlockHandler = ({ answer }) => {
  const [copiedCode, setCopiedCode] = useState(null);

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedCode(code);
      setTimeout(() => setCopiedCode(null), 2000);
    });
  };

  return (
    <div className="text-base text-gray-800 dark:text-gray-200 leading-relaxed max-w-3xl mx-auto">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({ node, ...props }) => <p className="mb-4" {...props} />,
          a: ({ node, ...props }) => (
            <a
              className="text-blue-600 dark:text-blue-400 underline hover:text-blue-800 dark:hover:text-blue-600"
              target="_blank"
              rel="noopener noreferrer"
              {...props}
            />
          ),
          strong: ({ node, ...props }) => (
            <strong className="font-semibold" {...props} />
          ),
          em: ({ node, ...props }) => <em className="italic" {...props} />,
          blockquote: ({ node, ...props }) => (
            <blockquote
              className="border-l-4 border-gray-300 dark:border-gray-600 pl-4 italic text-gray-600 dark:text-gray-400 mb-4"
              {...props}
            />
          ),
          ul: ({ node, ...props }) => (
            <ul className="list-disc pl-5 mb-4" {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol className="list-decimal pl-5 mb-4" {...props} />
          ),
          li: ({ node, ...props }) => (
            <li className="mb-2">{props.children}</li>
          ),
          code({ node, inline, className, children, ...props }) {
            const languageMatch = /language-(\w+)/.exec(className || "");
            const codeContent = String(children).replace(/\n$/, "");

            if (!inline && languageMatch) {
              return (
                <div className="relative my-4 w-full max-w-6xl">
                  <div className="w-full">
                    <div className="flex justify-between items-center bg-[#1d1f21] text-gray-600 dark:text-gray-300 px-2 py-2 rounded-t-lg">
                      <span className="text-sm font-medium text-gray-400 dark:text-gray-300">
                        {languageMatch[1]}
                      </span>
                      <button
                        onClick={() => copyToClipboard(codeContent)}
                        className="flex items-center text-gray-400 dark:text-gray-300 hover:text-gray-700 focus:outline-none"
                        aria-label="Copy code to clipboard"
                      >
                        {copiedCode === codeContent ? (
                          <FiCheck className="w-5 h-5 text-green-500" />
                        ) : (
                          <FiCopy className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="w-full overflow-hidden rounded-b-xl">
                    <div className="min-w-full">
                      <SyntaxHighlighter
                        language={languageMatch[1]}
                        style={atomDark}
                        customStyle={{
                          margin: 0,
                          padding: "1rem",
                          borderRadius: "0 0 0.75rem 0.75rem",
                        }}
                        wrapLines={true}
                        wrapLongLines={false}
                        {...props}
                      >
                        {codeContent}
                      </SyntaxHighlighter>
                    </div>
                  </div>
                </div>
              );
            } else if (inline) {
              return (
                <code className="bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 px-1 rounded">
                  {children}
                </code>
              );
            }
            return (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      >
        {answer}
      </ReactMarkdown>
    </div>
  );
};

export default CodeBlockHandler;

import React from "react";
import { useTheme } from "./ThemeContext";
import { useNavigate } from "react-router-dom";
import { BsHouse } from "react-icons/bs";

function Terms() {
  const { theme } = useTheme();
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col h-screen overflow-hidden bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
    >
      <div
        className={`flex flex-col h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
      >
        <button
          onClick={() => navigate("/mainPage")}
          className="h-14 bg-blue-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-b border-gray-200 dark:border-gray-700 flex items-center px-4 z-20"
        >
          <BsHouse size={20} />
        </button>

        <div className="overflow-y-auto flex-grow">
          <div className="flex justify-center items-center h-screen bg-blue-100 dark:bg-gray-800 p-4">
            <div className="w-full max-w-4xl h-[100vh] bg-white dark:bg-gray-900 shadow-md overflow-hidden flex flex-col">
              <div className="p-8 overflow-y-auto flex-grow">
                <ul className="list-none mb-6 text-gray-800 dark:text-gray-200">
                  <li className="mb-6">
                    <h1 className="text-2xl font-bold mb-3 text-gray-800 dark:text-gray-200">
                      Responxe.com Terms of Service
                    </h1>
                    <h2 className="text-l font-bold text-gray-800 dark:text-gray-200 mt-4">
                      Last Updated: Jan 17, 2025
                    </h2>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      Welcome to Responxe!
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      Thank you for choosing Responxe.com ("we," "our," or
                      "us"). These Terms of Service ("Terms") govern your access
                      to and use of our website, responxe.com, and the services
                      we provide. We’ve aimed to make them clear and easy to
                      understand, so please take a moment to read them. By using
                      Responxe.com, you agree to these Terms.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      1. About Our Services
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      Responxe.com provides a platform where you can interact
                      with a variety of powerful Gen AI models. This allows you
                      to ask questions, explore ideas, and receive insightful
                      answers. We’re excited to help you unlock the potential of
                      AI.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      2. Getting Started with a Subscription
                    </h2>
                    <p className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                      To access the full capabilities of Responxe, you'll need a
                      subscription. Here's what you need to know:
                    </p>
                    <ul className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                      <li className="mb-2">
                        <strong>Subscription Pricing:</strong> We offer a
                        monthly subscription for ongoing access to our platform.
                        You can find our current pricing and subscription
                        details on our{" "}
                        <a
                          href="/myplan"
                          className="text-blue-500 hover:underline"
                        >
                          Pricing Page
                        </a>
                        .
                      </li>
                      <li className="mb-2">
                        <strong>Automatic Renewal:</strong> To ensure
                        uninterrupted access to Responxe, your subscription will
                        automatically renew each month.
                      </li>
                      <li className="mb-2">
                        <strong>Payment Processing:</strong> Your subscription
                        payments will be securely processed by{" "}
                        <a
                          href="https://stripe.com/?utm_campaign=AMER_US_en_Google_Search_Brand_Stripe_EXA-20839462206&utm_medium=cpc&utm_source=google&ad_content=720842111268&utm_term=stripe&utm_matchtype=e&utm_adposition=&utm_device=c&gad_source=1&gclid=CjwKCAiAnKi8BhB0EiwA58DA4Z95VeEJQy8m65UOoSRWIig6nb_V2DwpzQV5MROw06xqUECD5H56lxoCisMQAvD_BwE"
                          className="text-blue-500 hover:underline"
                        >
                          Stripe
                        </a>
                        . You’ll need to provide accurate billing details.
                      </li>
                      <li className="mb-2">
                        <strong>Auto-Payment Agreement:</strong> By subscribing
                        to Responxe, you authorize us to charge your payment
                        method automatically at the start of each billing cycle.
                        This ensures you can continue to enjoy our services
                        without interruption. You can manage your payment
                        information anytime in your account settings.
                      </li>
                      <li className="mb-2">
                        <strong>Easy Cancellation:</strong> You may cancel your
                        subscription at any time. Upon cancellation, access will
                        continue until the end of the current billing cycle.
                        After cancellation, you will not be charged for any
                        future billing periods.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      3. User Responsibilities
                    </h2>
                    <p className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                      We want everyone to have a great experience. Here are a
                      few simple guidelines:
                    </p>
                    <ul className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                      <li className="mb-2">
                        <strong>Account Security:</strong> Keep your account
                        login information safe and secure. You’re responsible
                        for all activity on your account.
                      </li>
                      <li className="mb-2">
                        <strong>Respectful Usage:</strong> Please use Responxe
                        responsibly and ethically. Don't engage in any illegal
                        activities or anything that might disrupt our platform.
                      </li>
                    </ul>
                  </li>
                  {/* <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      5. Intellectual Property
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      All content and materials on the Service are owned by or
                      licensed to us. You may not reproduce, distribute, or
                      create derivative works without our explicit permission.
                    </p>
                  </li> */}
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      4. Limitation of Liability
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      We strive to provide you with a reliable service, but we
                      can't guarantee it will be perfect all the time. We're not
                      liable for any indirect or consequential damages that may
                      arise from your use of our platform.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      5. Service Termination
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      We hope it never comes to this, but we reserve the right
                      to terminate or suspend your access to Responxe if we find
                      that you’ve violated these Terms or are engaging in any
                      harmful activities to the platform or its other users.
                    </p>
                  </li>
                  {/* <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      8. Governing Law
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      These Terms of Service are governed by the laws of [Your
                      Jurisdiction]. Any disputes will be resolved in the courts
                      of [Your Jurisdiction].
                    </p>
                  </li> */}
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      6. Keeping Up-to-Date
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      We might update these Terms from time to time, but don't
                      worry, we will inform you of any major changes. Your
                      continued use of Responxe after any changes have been made
                      means you're agreeing to the updated Terms.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      7. Get in Touch
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      If you have any questions or concerns about these Terms,
                      please feel free to contact us:
                    </p>
                    <ul className="list-disc list-inside mt-2 ml-6">
                      <li>Email: gpthubai2024@gmail.com</li>
                      {/* <li>[Any other contact methods you provide]</li> */}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;

import React from "react";
import { BiMessageSquareAdd } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa";
import SidebarToggleButton from "./SidebarToggleButton"; // The button we extracted earlier
import Navigation from "./APICollection"; // Assuming you have this component

const HeaderBar = ({
  theme,
  toggleSidebar,
  sidebarOpen,
  startNewChat,
  toggleModelDropdown,
  isModelDropdownOpen,
  gptBarRef,
  selectedAPI,
  handleModelChange,
  userPlanType,
}) => {
  return (
    <div className="h-14 bg-white dark:bg-gray-900 text-gray-600 dark:text-gray-400 flex items-center px-4 z-20">
      <SidebarToggleButton
        toggleSidebar={toggleSidebar}
        sidebarOpen={sidebarOpen}
      />

      <button
        onClick={startNewChat}
        className="mr-4 text-gray-800 dark:text-gray-300 hover:text-gray-950 dark:hover:text-gray-200"
      >
        <BiMessageSquareAdd size={20} />
      </button>

      <div className="relative">
        <button
          onClick={toggleModelDropdown}
          className="flex items-center text-gray-800 dark:text-gray-300 hover:text-gray-950 dark:hover:text-gray-200 font-semibold"
        >
          {selectedAPI}
          <FaChevronDown size={12} className="ml-2" />
        </button>
        {isModelDropdownOpen && (
          <div
            ref={gptBarRef}
            className="absolute top-full left-0 w-80 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg text-sm"
          >
            <Navigation
              handleModelChange={handleModelChange}
              userPlanType={userPlanType}
              selectedModel={selectedAPI}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderBar;
